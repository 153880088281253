import { UserWarehouseTypeModel } from 'src/Models/Warehouse/UserWarehouseTypeModel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { warehouseTypeAsyncActions } from './asyncActions';
import { ModalDataType } from '@/Types/ModalDataType';

export type WarehouseTypeSettingsState = {
  warehouseTypes: UserWarehouseTypeModel[];
  warehouseTypeModal: ModalDataType<UserWarehouseTypeModel>;
};

const initialState: WarehouseTypeSettingsState = {
  warehouseTypes: [],
  warehouseTypeModal: { visible: false },
};

const warehouseTypesSlice = createSlice({
  name: 'userWarehouseTypeSettings',
  initialState,
  reducers: {
    setWarehouseTypeModal(state, action: PayloadAction<ModalDataType<UserWarehouseTypeModel>>) {
      state.warehouseTypeModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Types
    builder.addCase(warehouseTypeAsyncActions.getUserWarehouseTypes.fulfilled, (state, action) => {
      state.warehouseTypes = action.payload.userWarehouses;
    });
    builder.addCase(warehouseTypeAsyncActions.createUserWarehouseType.fulfilled, (state, action) => {
      state.warehouseTypes = [...state.warehouseTypes, action.payload.userWarehouseType];
    });
    builder.addCase(warehouseTypeAsyncActions.updateUserWarehouseType.fulfilled, (state, action) => {
      state.warehouseTypes = state.warehouseTypes.map((x) =>
        x.id === action.payload.userWarehouseType.id ? action.payload.userWarehouseType : x,
      );
    });
    builder.addCase(warehouseTypeAsyncActions.deleteUserWarehouseType.fulfilled, (state, action) => {
      state.warehouseTypes = state.warehouseTypes.filter((x) => x.id !== action.payload.id);
    });
  },
});

export const warehouseTypeSettingsActions = warehouseTypesSlice.actions;
export const warehouseTypeSettingsReducer = warehouseTypesSlice.reducer;
