import React, { useMemo } from 'react';
import { TourType } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';

export function useDocumentCategoriesSettings() {
  const {
    tours: {
      OnlineArchiveSettings: { DocumentCategories },
    },
  } = useLocalization();

  const pageTour = useMemo<TourType>(
    () => ({
      id: 'pageTour',
      title: DocumentCategories.Title,
      steps: [
        {
          content: <div>{DocumentCategories.Step1.Text}</div>,
          placement: 'auto',
          target: '#documentCategories',
          title: DocumentCategories.Step1.Title,
          disableBeacon: true,
          link: DocumentCategories.Step1.DetailsLink,
        },
        {
          content: <div>{DocumentCategories.Step2.Text}</div>,
          placement: 'auto',
          target: '#categoryList',
          title: DocumentCategories.Step2.Title,
          disableBeacon: true,
          link: DocumentCategories.Step2.DetailsLink,
        },
        {
          content: <div>{DocumentCategories.Step3.Text}</div>,
          placement: 'right',
          target: '#addItemButton',
          title: DocumentCategories.Step3.Title,
          disableBeacon: true,
          link: DocumentCategories.Step3.DetailsLink,
        },
        {
          content: <div>{DocumentCategories.Step4.Text}</div>,
          placement: 'auto',
          target: '#documentTypes',
          title: DocumentCategories.Step4.Title,
          disableBeacon: true,
          link: DocumentCategories.Step4.DetailsLink,
        },
        {
          content: <div>{DocumentCategories.Step5.Text}</div>,
          placement: 'auto',
          target: '#typeName',
          title: DocumentCategories.Step5.Title,
          disableBeacon: true,
          link: DocumentCategories.Step5.DetailsLink,
          styles: {
            spotlight: {
              marginTop: '-10px',
              marginLeft: '-10px',
            },
          },
        },
        {
          content: <div>{DocumentCategories.Step6.Text}</div>,
          placement: 'top',
          target: '#removalCount',
          title: DocumentCategories.Step6.Title,
          disableBeacon: true,
          link: DocumentCategories.Step6.DetailsLink,
          styles: {
            spotlight: {
              marginTop: '-10px',
            },
          },
        },
        {
          content: <div>{DocumentCategories.Step7.Text}</div>,
          placement: 'auto',
          target: '#removalDateType',
          title: DocumentCategories.Step7.Title,
          disableBeacon: true,
          link: DocumentCategories.Step7.DetailsLink,
        },
        {
          content: <div>{DocumentCategories.Step8.Text}</div>,
          placement: 'auto',
          target: '#typeCrud',
          title: DocumentCategories.Step8.Title,
          disableBeacon: true,
          link: DocumentCategories.Step8.DetailsLink,
        },
      ],
    }),
    [DocumentCategories],
  );

  return [pageTour];
}
