import React, { useMemo } from 'react';
import { TourType } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';
import { packagesSettingsActions } from '@/Pages/Settings/modules/PackageSettings/services/reducer';
import { PackageModel } from '@/Models';
import { AppDispatch } from '@/Redux/ConfigureStore';
import { useDispatch } from 'react-redux';

export function usePackageSettings() {
  const dispatch: AppDispatch = useDispatch();

  const {
    tours: {
      PackageSettings: { Default, Package },
    },
  } = useLocalization();

  const pageTour = useMemo<TourType>(
    () => ({
      id: 'pageTour',
      title: Default.Title,
      steps: [
        {
          content: <div>{Default.Step1.Text}</div>,
          placement: 'auto',
          target: '#packageTitle',
          title: Default.Step1.Title,
          disableBeacon: true,
          link: Default.Step1.DetailsLink,
        },
        {
          content: <div>{Default.Step2.Text}</div>,
          placement: 'auto',
          target: '#packageTable',
          title: Default.Step2.Title,
          disableBeacon: true,
          link: Default.Step2.DetailsLink,
        },
        {
          content: <div>{Default.Step3.Text}</div>,
          placement: 'auto',
          target: '#crudExtended',
          title: Default.Step3.Title,
          disableBeacon: true,
          link: Default.Step3.DetailsLink,
        },
        {
          content: <div>{Default.Step4.Text}</div>,
          placement: 'auto',
          target: '.dx-toolbar-after',
          title: Default.Step4.Title,
          disableBeacon: true,
          link: Default.Step4.DetailsLink,
          spotlightPadding: 0,
          styles: {
            spotlight: {
              maxWidth: '500px',
            },
          },
        },
        {
          content: <div>{Default.Step5.Text}</div>,
          placement: 'auto',
          target: '.dx-dropdownmenu-button',
          title: Default.Step5.Title,
          disableBeacon: true,
          link: Default.Step5.DetailsLink,
        },
        {
          content: <div>{Default.Step6.Text}</div>,
          placement: 'auto',
          target: '#packageForm',
          title: Default.Step6.Title,
          disableBeacon: true,
          link: Default.Step6.DetailsLink,
        },
      ],
    }),
    [Default],
  );

  const packageTour = useMemo<TourType>(
    () => ({
      id: 'packageTour',
      title: Package.Title,
      before: () => {
        dispatch(packagesSettingsActions.createPackage({} as PackageModel));
      },
      steps: [
        {
          content: <div>{Package.Step1.Text}</div>,
          placement: 'auto',
          target: '#packageSettingsTitle',
          title: Package.Step1.Title,
          disableBeacon: true,
          link: Package.Step1.DetailsLink,
        },
        {
          content: <div>{Package.Step2.Text}</div>,
          placement: 'auto',
          target: '#packageModalFields',
          title: Package.Step2.Title,
          disableBeacon: true,
          link: Package.Step2.DetailsLink,
        },
        {
          content: <div>{Package.Step3.Text}</div>,
          placement: 'auto',
          target: '#packageModalStatus',
          title: Package.Step3.Title,
          disableBeacon: true,
          link: Package.Step3.DetailsLink,
        },
      ],
    }),
    [Package],
  );

  return [pageTour, packageTour];
}
