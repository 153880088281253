import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TourState = {
  openSidebar: boolean;
  liftSidebar: boolean;
  run: boolean;
  tour: string;
  activeStep: number;
  selectedRoute: string;
};

const initialState: TourState = {
  openSidebar: false,
  liftSidebar: false,
  run: false,
  tour: '',
  activeStep: 0,
  selectedRoute: '',
};

const tourSlice = createSlice({
  name: 'tours',
  initialState,
  reducers: {
    setRun(state, action: PayloadAction<{ run: boolean; tour: string; open?: boolean }>) {
      state.tour = action.payload.tour;
      state.run = action.payload.run;
      state.openSidebar = action.payload.open ?? true;
      state.selectedRoute = location.pathname;
    },
    setActiveStep(state, action: PayloadAction<number>) {
      state.activeStep = action.payload;
    },
    setOpenSidebar(state, action: PayloadAction<boolean>) {
      state.openSidebar = action.payload;
    },
    setLiftSidebar(state, action: PayloadAction<boolean>) {
      state.liftSidebar = action.payload;
    },
    setSelectedRoute(state, action: PayloadAction<string>) {
      state.selectedRoute = action.payload;
    },
  },
});

export const tourReducer = tourSlice.reducer;
export const tourActions = tourSlice.actions;
