import React, { useMemo } from 'react';
import { TourType } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';
import { logisticSettingsActions } from '@/Pages/Settings/modules/LogisticsSettings/services/reducer';
import { AppDispatch } from '@/Redux/ConfigureStore';
import { useDispatch } from 'react-redux';
import { CountryCode, LogisticTypeKeys } from '@/Enums';

export function useLogisticSettings() {
  const dispatch: AppDispatch = useDispatch();

  const {
    tours: {
      LogisticSettings: { PageTour, LogisticTour, ConnectTour, AccountSettingsTour },
    },
  } = useLocalization();

  const pageTour = useMemo<TourType>(
    () => ({
      id: 'pageTour',
      title: PageTour.Title,
      steps: [
        {
          content: <div>{PageTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#logisticTitle',
          title: PageTour.Step1.Title,
          disableBeacon: true,
          link: PageTour.Step1.DetailsLink,
        },
        {
          content: <div>{PageTour.Step2.Text}</div>,
          placement: 'auto',
          target: '#logisticDropDown',
          title: PageTour.Step2.Title,
          disableBeacon: true,
          link: PageTour.Step2.DetailsLink,
          styles: {
            spotlight: {
              minHeight: '110px',
            },
          },
        },
        {
          content: <div>{PageTour.Step3.Text}</div>,
          placement: 'auto',
          target: '#contractsContainer',
          title: PageTour.Step3.Title,
          disableBeacon: true,
          link: PageTour.Step3.DetailsLink,
        },
        {
          content: <div>{PageTour.Step4.Text}</div>,
          placement: 'auto',
          target: '#connectContract',
          title: PageTour.Step4.Title,
          disableBeacon: true,
          link: PageTour.Step4.DetailsLink,
        },
      ],
    }),
    [PageTour],
  );

  const logisticTour = useMemo<TourType>(
    () => ({
      id: 'logisticTour',
      title: LogisticTour.Title,
      before: () => {
        dispatch(
          logisticSettingsActions.setLogisticInfoModal({
            visible: true,
            data: {
              logo: '',
              genLogisticTypeId: LogisticTypeKeys.Dpd,
              address: 'Kaiserstr 19h, 76646 BRUCHSAL,',
              country: CountryCode.DE,
              mandatId: 1,
              mandatName: 'Manual',
              warehouseId: 1,
              warehouseName: 'Default warehouse',
              contracts: [{ id: 1, name: 'Default', isActive: true }],
            },
          }),
        );
      },
      after: () => {
        dispatch(logisticSettingsActions.setLogisticInfoModal({ visible: false }));
      },
      steps: [
        {
          content: <div>{LogisticTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#logisticModalTitle',
          title: LogisticTour.Step1.Title,
          disableBeacon: true,
          link: LogisticTour.Step1.DetailsLink,
        },
        {
          content: <div>{LogisticTour.Step2.Text}</div>,
          placement: 'auto',
          target: '#logisticInfo',
          title: LogisticTour.Step2.Title,
          disableBeacon: true,
          link: LogisticTour.Step2.DetailsLink,
        },
        {
          content: <div>{LogisticTour.Step3.Text}</div>,
          placement: 'auto',
          target: '#contractsList',
          title: LogisticTour.Step3.Title,
          disableBeacon: true,
          link: LogisticTour.Step3.DetailsLink,
        },
        {
          content: <div>{LogisticTour.Step4.Text}</div>,
          placement: 'auto',
          target: '#contractStatus',
          title: LogisticTour.Step4.Title,
          disableBeacon: true,
          link: LogisticTour.Step4.DetailsLink,
        },
        {
          content: <div>{LogisticTour.Step5.Text}</div>,
          placement: 'auto',
          target: '#contractActions',
          title: LogisticTour.Step5.Title,
          disableBeacon: true,
          link: LogisticTour.Step5.DetailsLink,
        },
        {
          content: <div>{LogisticTour.Step6.Text}</div>,
          placement: 'auto',
          target: '#addContract',
          title: LogisticTour.Step6.Title,
          disableBeacon: true,
          link: LogisticTour.Step6.DetailsLink,
        },
      ],
    }),
    [LogisticTour],
  );

  const connectTour = useMemo<TourType>(
    () => ({
      id: 'connectTour',
      title: ConnectTour.Title,
      before: () => {
        dispatch(logisticSettingsActions.setConnectModal({ visible: true }));
      },
      after: () => {
        dispatch(logisticSettingsActions.setConnectModal({ visible: false }));
      },
      steps: [
        {
          content: <div>{ConnectTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#connectLogisticType',
          title: ConnectTour.Step1.Title,
          disableBeacon: true,
          link: ConnectTour.Step1.DetailsLink,
        },
      ],
    }),
    [ConnectTour],
  );

  const accountSettingsTour = useMemo<TourType>(
    () => ({
      id: 'accountSettingsTour',
      title: AccountSettingsTour.Title,
      before: () => {
        dispatch(
          logisticSettingsActions.setContractModal({
            visible: true,
            locationId: 1,
            mandatId: 1,
            logisticType: LogisticTypeKeys.Dpd,
          }),
        );
      },
      after: () => {
        dispatch(logisticSettingsActions.setContractModal({ visible: true }));
      },
      steps: [
        {
          content: <div>{AccountSettingsTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#accountSettings',
          title: AccountSettingsTour.Step1.Title,
          disableBeacon: true,
          link: AccountSettingsTour.Step1.DetailsLink,
          callback: () => dispatch(logisticSettingsActions.setContractActiveStep(0)),
        },
        {
          content: <div>{AccountSettingsTour.Step2.Text}</div>,
          placement: 'auto',
          target: '#accountName',
          title: AccountSettingsTour.Step2.Title,
          disableBeacon: true,
          link: AccountSettingsTour.Step2.DetailsLink,
          callback: () => dispatch(logisticSettingsActions.setContractActiveStep(0)),
        },
        {
          content: <div>{AccountSettingsTour.Step3.Text}</div>,
          placement: 'auto',
          target: '#accountFields',
          title: AccountSettingsTour.Step3.Title,
          disableBeacon: true,
          link: AccountSettingsTour.Step3.DetailsLink,
          callback: () => dispatch(logisticSettingsActions.setContractActiveStep(0)),
        },
        {
          content: <div>{AccountSettingsTour.Step4.Text}</div>,
          placement: 'auto',
          target: '#checkConnection',
          title: AccountSettingsTour.Step4.Title,
          disableBeacon: true,
          link: AccountSettingsTour.Step4.DetailsLink,
          callback: () => dispatch(logisticSettingsActions.setContractActiveStep(0)),
        },
        {
          content: <div>{AccountSettingsTour.Step5.Text}</div>,
          placement: 'auto',
          target: '#shippingService',
          title: AccountSettingsTour.Step5.Title,
          disableBeacon: true,
          link: AccountSettingsTour.Step5.DetailsLink,
          callback: () => dispatch(logisticSettingsActions.setContractActiveStep(1)),
        },
        {
          content: <div>{AccountSettingsTour.Step6.Text}</div>,
          placement: 'auto',
          target: '#shippingCurier',
          title: AccountSettingsTour.Step6.Title,
          disableBeacon: true,
          link: AccountSettingsTour.Step6.DetailsLink,
          callback: () => dispatch(logisticSettingsActions.setContractActiveStep(1)),
        },
        {
          content: <div>{AccountSettingsTour.Step7.Text}</div>,
          placement: 'auto',
          target: '#shippingPickup',
          title: AccountSettingsTour.Step7.Title,
          disableBeacon: true,
          link: AccountSettingsTour.Step7.DetailsLink,
          callback: () => dispatch(logisticSettingsActions.setContractActiveStep(1)),
        },
        {
          content: <div>{AccountSettingsTour.Step8.Text}</div>,
          placement: 'auto',
          target: '#shippingCalendar',
          title: AccountSettingsTour.Step8.Title,
          disableBeacon: true,
          link: AccountSettingsTour.Step8.DetailsLink,
          callback: () => dispatch(logisticSettingsActions.setContractActiveStep(1)),
        },

        {
          content: <div>{AccountSettingsTour.Step9.Text}</div>,
          placement: 'auto',
          target: '#printerSettings',
          title: AccountSettingsTour.Step9.Title,
          disableBeacon: true,
          link: AccountSettingsTour.Step9.DetailsLink,
          callback: () => dispatch(logisticSettingsActions.setContractActiveStep(2)),
        },
        {
          content: <div>{AccountSettingsTour.Step10.Text}</div>,
          placement: 'auto',
          target: '#labelFormat',
          title: AccountSettingsTour.Step10.Title,
          disableBeacon: true,
          link: AccountSettingsTour.Step10.DetailsLink,
          callback: () => dispatch(logisticSettingsActions.setContractActiveStep(2)),
        },
        {
          content: <div>{AccountSettingsTour.Step11.Text}</div>,
          placement: 'auto',
          target: '#referenceType',
          title: AccountSettingsTour.Step11.Title,
          disableBeacon: true,
          link: AccountSettingsTour.Step11.DetailsLink,
          callback: () => dispatch(logisticSettingsActions.setContractActiveStep(2)),
        },
        {
          content: <div>{AccountSettingsTour.Step12.Text}</div>,
          placement: 'auto',
          target: '#orderMarker',
          title: AccountSettingsTour.Step12.Title,
          disableBeacon: true,
          link: AccountSettingsTour.Step12.DetailsLink,
          callback: () => dispatch(logisticSettingsActions.setContractActiveStep(2)),
        },
      ],
    }),
    [AccountSettingsTour],
  );

  return [pageTour, logisticTour, connectTour, accountSettingsTour];
}
