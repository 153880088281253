import React, { useMemo } from 'react';
import { TourType } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';

export function useGeneralArchiveSettings() {
  const {
    tours: {
      OnlineArchiveSettings: { General },
    },
  } = useLocalization();

  const pageTour = useMemo<TourType>(
    () => ({
      id: 'pageTour',
      title: General.Title,
      steps: [
        {
          content: <div>{General.Step1.Text}</div>,
          placement: 'auto',
          target: '#onlineArchiveTitle',
          title: General.Step1.Title,
          disableBeacon: true,
          link: General.Step1.DetailsLink,
        },
        {
          content: <div>{General.Step2.Text}</div>,
          placement: 'auto',
          target: '#general',
          title: General.Step2.Title,
          disableBeacon: true,
          link: General.Step2.DetailsLink,
        },
      ],
    }),
    [General],
  );

  return [pageTour];
}
