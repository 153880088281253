import React, { useState } from 'react';
import styles from './RouteItem.scss';
import { globalHistory } from '@/GlobalHistory';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { tourActions } from '@/Redux/Tours/reducer';

export type RouteItem = {
  title: string;
  route: string;
  children?: RouteItem[];
};

type Props = {
  idx: number;
  node: RouteItem;
};

export const RouteItem = ({ node, idx }: Props) => {
  const dispatch = useDispatch();

  const isActive = location.pathname.startsWith(node.route);

  const [isExpanded, setIsExpanded] = useState(isActive);

  const onSelectPage = () => {
    if (!node.children) {
      globalHistory.push(node.route);
      dispatch(tourActions.setSelectedRoute(node.route));
    }
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <div onClick={onSelectPage} className={cn(styles.routeItem, { [styles.active]: isActive })}>
        {idx}. {node.title}
      </div>
      {isExpanded && node.children && (
        <div className={styles.itemContent}>
          {node.children.map((child, idx) => (
            <RouteItem key={child.title} idx={idx + 1} node={child} />
          ))}
        </div>
      )}
    </div>
  );
};
