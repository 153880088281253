import React from 'react';
import styles from './DeliveryInfo.scss';

export function DeliveryInfo() {
  return (
    <div className={styles.section}>
      {/* <DeliveryStatusIcons
        isFirst={true}
        deliveryProgressInfo={{
          id: 1,
          number: '012345678901234567890123456789',
          currentCompleteStep: DeliveryProgressStepType.Complete,
          logistic: LogisticType.Dpd,
          marketplace: MarketplaceType.Ebay,
          packageName: 'Brief 20g',
          orderId: 1
        }}
      /> */}
      {/* <DeliveryStatusIcons
        deliveryProgressInfo={{
          id: 2,
          number: '012345678901234567890123456789',
          currentCompleteStep: DeliveryProgressStepType.Delivery,
        }}
      />
      <DeliveryStatusIcons
        deliveryProgressInfo={{
          id: 3,
          number: '012345678901234567890123456789',
          currentCompleteStep: DeliveryProgressStepType.Invoice,
        }}
      />
      <DeliveryStatusIcons
        deliveryProgressInfo={{
          id: 4,
          number: '012345678901234567890123456789',
          currentCompleteStep: DeliveryProgressStepType.Complete,
        }}
      /> */}
    </div>
  );
}
