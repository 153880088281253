import { createSelector } from '@reduxjs/toolkit';
import { settingsPageSelectors } from '@/Pages/Settings/services/selectors';
import { DocumentTemplateSettingsState } from './reducer';

const templates = createSelector(
  settingsPageSelectors.documentTemplateSettings,
  (documentTemplateState: DocumentTemplateSettingsState) => documentTemplateState.templates,
);

const selectedTemplate = createSelector(
  settingsPageSelectors.documentTemplateSettings,
  (documentTemplateState: DocumentTemplateSettingsState) => documentTemplateState.selectedTemplate,
);

const printers = createSelector(
  settingsPageSelectors.documentTemplateSettings,
  (documentTemplateState: DocumentTemplateSettingsState) => documentTemplateState.printers,
);

const selectedPrinterName = createSelector(
  settingsPageSelectors.documentTemplateSettings,
  (documentTemplateState: DocumentTemplateSettingsState) => documentTemplateState.selectedPrinterName,
);

const isPrintPreview = createSelector(
  settingsPageSelectors.documentTemplateSettings,
  (documentTemplateState: DocumentTemplateSettingsState) => documentTemplateState.isPrintPreview,
);

const defaultPrinter = createSelector(
  settingsPageSelectors.documentTemplateSettings,
  (documentTemplateState: DocumentTemplateSettingsState) => documentTemplateState.defaultPrinter,
);

const modalWindowType = createSelector(
  settingsPageSelectors.documentTemplateSettings,
  (documentTemplateState: DocumentTemplateSettingsState) => documentTemplateState.modalWindowType,
);

export const documentTemplateSettingsSelectors = {
  templates,
  selectedTemplate,
  printers,
  selectedPrinterName,
  isPrintPreview,
  defaultPrinter,
  modalWindowType,
};
