import React, { useMemo } from 'react';
import { TourType } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';
import { documentTemplateActions } from '@/Pages/Settings/modules/DocumentTemplateSettings/services';
import { DocumentTemplateModalType } from '@/Enums';
import { useDispatch } from 'react-redux';

export function useDocumentTemplateSettings() {
  const dispatch = useDispatch();

  const {
    tours: {
      DocumentTemplateSettings: { PageTour, TemplateTour },
    },
  } = useLocalization();

  const pageTour = useMemo<TourType>(
    () => ({
      id: 'pageTour',
      title: PageTour.Title,
      steps: [
        {
          content: <div>{PageTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#templateTitle',
          title: PageTour.Step1.Title,
          disableBeacon: true,
          link: PageTour.Step1.DetailsLink,
        },
        {
          content: <div>{PageTour.Step2.Text}</div>,
          placement: 'left',
          target: '#templatesTable',
          title: PageTour.Step2.Title,
          disableBeacon: true,
          link: PageTour.Step2.DetailsLink,
        },
        {
          content: <div>{PageTour.Step3.Text}</div>,
          placement: 'auto',
          target: '#crudExtended',
          title: PageTour.Step3.Title,
          disableBeacon: true,
          link: PageTour.Step3.DetailsLink,
        },
        {
          content: <div>{PageTour.Step4.Text}</div>,
          placement: 'auto',
          target: '.dx-toolbar-after',
          title: PageTour.Step4.Title,
          disableBeacon: true,
          link: PageTour.Step4.DetailsLink,
          spotlightPadding: 0,
          styles: {
            spotlight: {
              maxWidth: '475px',
            },
          },
        },
        {
          content: <div>{PageTour.Step5.Text}</div>,
          placement: 'auto',
          target: '.dx-dropdownmenu-button',
          title: PageTour.Step5.Title,
          disableBeacon: true,
          link: PageTour.Step5.DetailsLink,
        },
      ],
    }),
    [PageTour],
  );

  const templateTour = useMemo<TourType>(
    () => ({
      id: 'templateTour',
      title: TemplateTour.Title,
      before: () => {
        dispatch(documentTemplateActions.openModal(DocumentTemplateModalType.AddOrModify));
      },
      steps: [
        {
          content: <div>{TemplateTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#templateName',
          title: TemplateTour.Step1.Title,
          disableBeacon: true,
          link: TemplateTour.Step1.DetailsLink,
        },
        {
          content: <div>{TemplateTour.Step2.Text}</div>,
          placement: 'auto',
          target: '#savePanel',
          title: TemplateTour.Step2.Title,
          disableBeacon: true,
          link: TemplateTour.Step2.DetailsLink,
        },
        {
          content: <div>{TemplateTour.Step3.Text}</div>,
          placement: 'auto',
          target: '#savePath',
          title: TemplateTour.Step3.Title,
          disableBeacon: true,
          link: TemplateTour.Step3.DetailsLink,
        },
        {
          content: <div>{TemplateTour.Step4.Text}</div>,
          placement: 'auto',
          target: '#templateCompartment',
          title: TemplateTour.Step4.Title,
          disableBeacon: true,
          link: TemplateTour.Step4.DetailsLink,
        },
        {
          content: <div>{TemplateTour.Step5.Text}</div>,
          placement: 'auto',
          target: '#setStatusPanel',
          title: TemplateTour.Step5.Title,
          disableBeacon: true,
          link: TemplateTour.Step4.DetailsLink,
        },
        {
          content: <div>{TemplateTour.Step6.Text}</div>,
          placement: 'auto',
          target: '#templateAutomation',
          title: TemplateTour.Step6.Title,
          disableBeacon: true,
          link: TemplateTour.Step6.DetailsLink,
        },
        {
          content: <div>{TemplateTour.Step7.Text}</div>,
          placement: 'left',
          target: '#documentTemplate',
          title: TemplateTour.Step7.Title,
          disableBeacon: true,
          link: TemplateTour.Step7.DetailsLink,
        },
      ],
    }),
    [TemplateTour],
  );

  return [pageTour, templateTour];
}
