import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetUserWarehouseTypesResponse } from 'src/Api/UserWarehouse/UserWarehouseTypes/Responses/GetUserWarehouseTypesResponse';
import { UserWarehouseTypesApi } from 'src/Api/UserWarehouse/UserWarehouseTypes/UserWarehouseTypesApi';
import { CreateUserWarehouseTypeResponse } from 'src/Api/UserWarehouse/UserWarehouseTypes/Responses/CreateUserWarehouseTypeResponse';
import { CreateUserWarehouseTypeRequest } from 'src/Api/UserWarehouse/UserWarehouseTypes/Requests/CreateUserWarehouseTypeRequest';
import { UpdateUserWarehouseTypeRequest } from 'src/Api/UserWarehouse/UserWarehouseTypes/Requests/UpdateUserWarehouseTypeRequest';
import { UpdateUserWarehouseTypeResponse } from 'src/Api/UserWarehouse/UserWarehouseTypes/Responses/UpdateUserWarehouseTypeResponse';
import { DeleteUserWarehouseTypeRequest } from 'src/Api/UserWarehouse/UserWarehouseTypes/Requests/DeleteUserWarehouseTypeRequest';
import { DeleteUserWarehouseTypeResponse } from 'src/Api/UserWarehouse/UserWarehouseTypes/Responses/DeleteUserWarehouseTypeResponse';

const getUserWarehouseTypes = createAsyncThunk<GetUserWarehouseTypesResponse, void>('user-warehouse-types/get', () => {
  return UserWarehouseTypesApi.getUserWarehouseTypes();
});

const createUserWarehouseType = createAsyncThunk<CreateUserWarehouseTypeResponse, CreateUserWarehouseTypeRequest>(
  'user-warehouse-types/create',
  (request) => {
    return UserWarehouseTypesApi.createUserWarehouseType(request);
  },
);

const updateUserWarehouseType = createAsyncThunk<UpdateUserWarehouseTypeResponse, UpdateUserWarehouseTypeRequest>(
  'user-warehouse-types/update',
  (request) => {
    return UserWarehouseTypesApi.updateUserWarehouseType(request);
  },
);

const deleteUserWarehouseType = createAsyncThunk<DeleteUserWarehouseTypeResponse, DeleteUserWarehouseTypeRequest>(
  'user-warehouse-types/delete',
  (request) => {
    return UserWarehouseTypesApi.deleteUserWarehouseType(request);
  },
);

export const warehouseTypeAsyncActions = {
  getUserWarehouseTypes,
  createUserWarehouseType,
  updateUserWarehouseType,
  deleteUserWarehouseType,
};
