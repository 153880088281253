import { useLocalization } from '@/Hooks';
import { PagesRouting } from '@/Routing';
import { useMemo } from 'react';

export const useRouteItems = () => {
  const {
    modules,
    onlineArchive,
    settingsPage: { Items, OnlineArchiveSettings, ShippingAutomationSettings },
  } = useLocalization();

  return useMemo(() => {
    return [
      {
        title: modules.Dashboard,
        route: PagesRouting.MainPages.DashboardPage.Dashboard,
      },
      {
        title: modules.Sales,
        route: PagesRouting.MainPages.SalesPage.Sales,
      },
      {
        title: modules.Purchases,
        route: PagesRouting.MainPages.PurchasesPage,
      },
      {
        title: modules.Shop,
        route: PagesRouting.MainPages.ShopPage.Shop,
      },
      {
        title: modules.Messages,
        route: PagesRouting.MainPages.MessagesPage,
      },
      {
        title: modules.OnlineArchive,
        route: PagesRouting.MainPages.OnlineArchivePage.OnlineArchive,
        children: [
          {
            title: onlineArchive.Tabs.Upload,
            route: PagesRouting.MainPages.OnlineArchivePage.UploadTab,
          },
          {
            title: onlineArchive.Tabs.Allocate,
            route: PagesRouting.MainPages.OnlineArchivePage.AllocateTab,
          },
          {
            title: onlineArchive.Tabs.Archive,
            route: PagesRouting.MainPages.OnlineArchivePage.ArchiveTab,
          },
          {
            title: onlineArchive.Tabs.Reports,
            route: PagesRouting.MainPages.OnlineArchivePage.ReportsTab,
          },
        ],
      },
      {
        title: modules.Listing,
        route: PagesRouting.MainPages.ListingPage,
      },
      {
        title: modules.Logistic,
        route: PagesRouting.MainPages.LogisticsPage.Logistics,
      },
      {
        title: modules.Products,
        route: PagesRouting.MainPages.ProductsPage.Products,
      },
      {
        title: modules.Warehouse,
        route: PagesRouting.MainPages.WarehousePage.Warehouse,
      },
      {
        title: modules.Customers,
        route: PagesRouting.MainPages.CustomersPage.Customers,
      },
      {
        title: modules.Suppliers,
        route: PagesRouting.MainPages.SuppliersPage.Suppliers,
      },
      {
        title: modules.DesignService,
        route: PagesRouting.MainPages.DesignServicePage,
      },
      {
        title: modules.Accounting,
        route: PagesRouting.MainPages.AccountingPage.Accounting,
      },
      {
        title: modules.Settings,
        route: PagesRouting.SettingsPages.Settings,
        children: [
          {
            title: Items.Account,
            route: PagesRouting.SettingsPages.AccountSettings,
          },
          {
            title: Items.Synchronize,
            route: PagesRouting.SettingsPages.SynchronizeSettings,
          },
          {
            title: Items.Mandats,
            route: PagesRouting.SettingsPages.MandatsSettings,
          },
          {
            title: Items.OrderAutomation,
            route: PagesRouting.SettingsPages.OrderAutomationSettings,
          },
          {
            title: Items.Logistic,
            route: PagesRouting.SettingsPages.LogisticSettings,
          },
          {
            title: Items.ShippingAutomation,
            route: PagesRouting.SettingsPages.ShippingAutomationSettings.ShippingAutomation,
            children: [
              {
                title: ShippingAutomationSettings.Tabs.AmountAutomation,
                route: PagesRouting.SettingsPages.ShippingAutomationSettings.AmountAutomation,
              },
              {
                title: ShippingAutomationSettings.Tabs.ItemsCategoryShippingAutomation,
                route: PagesRouting.SettingsPages.ShippingAutomationSettings.CategoryAutomation,
              },
              {
                title: ShippingAutomationSettings.Tabs.ItemsCategoryPackagingAutomation,
                route: PagesRouting.SettingsPages.ShippingAutomationSettings.PackagingAutomation,
              },
              {
                title: ShippingAutomationSettings.Tabs.MixCategoriesAutomation,
                route: PagesRouting.SettingsPages.ShippingAutomationSettings.MixCategoryAutomation,
              },
            ],
          },
          {
            title: Items.AddressBook,
            route: PagesRouting.SettingsPages.AddressBookSettings,
          },
          {
            title: Items.Packages,
            route: PagesRouting.SettingsPages.PackagesSettings,
          },
          {
            title: Items.Marketplaces,
            route: PagesRouting.SettingsPages.MarketplacesSettings,
          },
          {
            title: Items.OnlineArchive,
            route: PagesRouting.SettingsPages.OnlineArchiveSetting.OnlineArchive,
            children: [
              {
                title: OnlineArchiveSettings.Tabs.General,
                route: PagesRouting.SettingsPages.OnlineArchiveSetting.General,
              },
              {
                title: OnlineArchiveSettings.Tabs.DocumentCategories,
                route: PagesRouting.SettingsPages.OnlineArchiveSetting.DocumentCategories,
              },
              {
                title: OnlineArchiveSettings.Tabs.AllocateCategories,
                route: PagesRouting.SettingsPages.OnlineArchiveSetting.AllocateCategories,
              },
              {
                title: OnlineArchiveSettings.Tabs.UploadTemplates,
                route: PagesRouting.SettingsPages.OnlineArchiveSetting.UploadTemplates,
              },
              {
                title: OnlineArchiveSettings.Tabs.AccountChart,
                route: PagesRouting.SettingsPages.OnlineArchiveSetting.AccountChart,
              },
              {
                title: OnlineArchiveSettings.Tabs.ScanDocuments,
                route: PagesRouting.SettingsPages.OnlineArchiveSetting.ScanDocuments,
              },
            ],
          },
          {
            title: Items.Warehouse,
            route: PagesRouting.SettingsPages.WarehouseSettings,
          },
          {
            title: Items.Products,
            route: PagesRouting.SettingsPages.ProductsSettings.Categories,
          },
          {
            title: Items.Invoices,
            route: PagesRouting.SettingsPages.InvoicesSettings,
          },
          {
            title: Items.Feedbacks,
            route: PagesRouting.SettingsPages.FeedbacksSettings,
          },
          {
            title: Items.GridsColours,
            route: PagesRouting.SettingsPages.GridsColorsSettings,
          },
          {
            title: Items.Integrations,
            route: PagesRouting.SettingsPages.IntegrationsSettings,
          },
          {
            title: Items.ShipCheaperApi,
            route: PagesRouting.SettingsPages.ShipCheaperApiSettings,
          },
          {
            title: Items.Help,
            route: PagesRouting.SettingsPages.HelpSettings,
          },
          {
            title: Items.Info,
            route: PagesRouting.SettingsPages.InfoSettings,
          },
          {
            title: Items.DocumentTemplates,
            route: PagesRouting.SettingsPages.DocumentTemplateSettings,
          },
          {
            title: Items.EmailTemplates,
            route: PagesRouting.SettingsPages.EmailTemplateSettings,
          },
        ],
      },
    ];
  }, [modules]);
};
