import React, { useMemo } from 'react';
import { TourType } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';
import { warehouseLocationSettingsActions } from '@/Pages/Settings/modules/WarehouseSettings/services/warehouseLocations/reducer';
import { useDispatch } from 'react-redux';
import { warehouseTypeSettingsActions } from '@/Pages/Settings/modules/WarehouseSettings/services/warehouseTypes/reducer';
import { warehouseTemplateSettingsActions } from '@/Pages/Settings/modules/WarehouseSettings/services/warehouseTemplates/reducer';

export function useWarehouseSettings() {
  const dispatch = useDispatch();

  const {
    tours: {
      WarehouseSettings: {
        PageTour,
        WarehouseTypeTour,
        WarehouseLocationTour,
        WarehouseTemplateTour,
        TemplateUnitTour,
      },
    },
  } = useLocalization();

  const pageTour = useMemo<TourType>(
    () => ({
      id: 'pageTour',
      title: PageTour.Title,
      steps: [
        {
          content: <div>{PageTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#warehouseTitle',
          title: PageTour.Step1.Title,
          disableBeacon: true,
          link: PageTour.Step1.DetailsLink,
        },
        {
          content: <div>{PageTour.Step2.Text}</div>,
          placement: 'auto',
          target: '#warehouseTypes',
          title: PageTour.Step2.Title,
          disableBeacon: true,
          link: PageTour.Step2.DetailsLink,
        },
        {
          content: <div>{PageTour.Step3.Text}</div>,
          placement: 'auto',
          target: '#warehouseType',
          title: PageTour.Step3.Title,
          disableBeacon: true,
          link: PageTour.Step3.DetailsLink,
        },
        {
          content: <div>{PageTour.Step4.Text}</div>,
          placement: 'auto',
          target: '#addWarehouseType',
          title: PageTour.Step4.Title,
          disableBeacon: true,
          link: PageTour.Step4.DetailsLink,
        },
        {
          content: <div>{PageTour.Step5.Text}</div>,
          placement: 'auto',
          target: '#crudExtended',
          title: PageTour.Step5.Title,
          disableBeacon: true,
          link: PageTour.Step5.DetailsLink,
        },
        {
          content: <div>{PageTour.Step6.Text}</div>,
          placement: 'auto',
          target: '#warehouseLocations',
          title: PageTour.Step6.Title,
          disableBeacon: true,
          link: PageTour.Step6.DetailsLink,
        },
        {
          content: <div>{PageTour.Step7.Text}</div>,
          placement: 'auto',
          target: '#warehouseLocation',
          title: PageTour.Step7.Title,
          disableBeacon: true,
          link: PageTour.Step7.DetailsLink,
        },
        {
          content: <div>{PageTour.Step8.Text}</div>,
          placement: 'auto',
          target: '#addWarehouseLocation',
          title: PageTour.Step8.Title,
          disableBeacon: true,
          link: PageTour.Step8.DetailsLink,
        },
        {
          content: <div>{PageTour.Step9.Text}</div>,
          placement: 'auto',
          target: '#warehouseTemplates',
          title: PageTour.Step9.Title,
          disableBeacon: true,
          link: PageTour.Step9.DetailsLink,
        },
        {
          content: <div>{PageTour.Step10.Text}</div>,
          placement: 'auto',
          target: '#warehouseTemplate',
          title: PageTour.Step10.Title,
          disableBeacon: true,
          link: PageTour.Step10.DetailsLink,
        },
        {
          content: <div>{PageTour.Step11.Text}</div>,
          placement: 'auto',
          target: '#addWarehouseTemplate',
          title: PageTour.Step11.Title,
          disableBeacon: true,
          link: PageTour.Step11.DetailsLink,
        },
      ],
    }),
    [PageTour],
  );

  const warehouseTypeTour = useMemo<TourType>(
    () => ({
      id: 'warehouseTypeTour',
      title: WarehouseTypeTour.Title,
      before: () => {
        dispatch(warehouseTypeSettingsActions.setWarehouseTypeModal({ visible: true }));
      },
      steps: [
        {
          content: <div>{WarehouseTypeTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#warehouseTypeTitle',
          title: WarehouseTypeTour.Step1.Title,
          disableBeacon: true,
          link: WarehouseTypeTour.Step1.DetailsLink,
        },
        {
          content: <div>{WarehouseTypeTour.Step2.Text}</div>,
          placement: 'auto',
          target: '#warehouseTypeName',
          title: WarehouseTypeTour.Step2.Title,
          disableBeacon: true,
          link: WarehouseTypeTour.Step2.DetailsLink,
        },
        {
          content: <div>{WarehouseTypeTour.Step3.Text}</div>,
          placement: 'auto',
          target: '#warehouseTypeColor',
          title: WarehouseTypeTour.Step3.Title,
          disableBeacon: true,
          link: WarehouseTypeTour.Step3.DetailsLink,
        },
        {
          content: <div>{WarehouseTypeTour.Step4.Text}</div>,
          placement: 'auto',
          target: '#warehouseTypeActive',
          title: WarehouseTypeTour.Step4.Title,
          disableBeacon: true,
          link: WarehouseTypeTour.Step4.DetailsLink,
        },
        {
          content: <div>{WarehouseTypeTour.Step5.Text}</div>,
          placement: 'auto',
          target: '#warehouseTypeFlags',
          title: WarehouseTypeTour.Step5.Title,
          disableBeacon: true,
          link: WarehouseTypeTour.Step5.DetailsLink,
        },
        {
          content: <div>{WarehouseTypeTour.Step6.Text}</div>,
          placement: 'auto',
          target: '#buttonsWrapper',
          title: WarehouseTypeTour.Step6.Title,
          disableBeacon: true,
          link: WarehouseTypeTour.Step6.DetailsLink,
        },
      ],
    }),
    [WarehouseTypeTour],
  );

  const warehouseLocationTour = useMemo<TourType>(
    () => ({
      id: 'warehouseLocationTour',
      title: WarehouseLocationTour.Title,
      before: () => {
        dispatch(warehouseLocationSettingsActions.setWarehouseLocationModal({ visible: true }));
      },
      steps: [
        {
          content: <div>{WarehouseLocationTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#warehouseLocationTitle',
          title: WarehouseLocationTour.Step1.Title,
          disableBeacon: true,
          link: WarehouseLocationTour.Step1.DetailsLink,
        },
        {
          content: <div>{WarehouseLocationTour.Step2.Text}</div>,
          placement: 'auto',
          target: '#warehouseLocationActive',
          title: WarehouseLocationTour.Step2.Title,
          disableBeacon: true,
          link: WarehouseLocationTour.Step2.DetailsLink,
        },
        {
          content: <div>{WarehouseLocationTour.Step3.Text}</div>,
          placement: 'auto',
          target: '#locationInfo',
          title: WarehouseLocationTour.Step3.Title,
          disableBeacon: true,
          link: WarehouseLocationTour.Step3.DetailsLink,
        },
        {
          content: <div>{WarehouseLocationTour.Step4.Text}</div>,
          placement: 'auto',
          target: '#addressInfo',
          title: WarehouseLocationTour.Step4.Title,
          disableBeacon: true,
          link: WarehouseLocationTour.Step4.DetailsLink,
        },
        {
          content: <div>{WarehouseLocationTour.Step5.Text}</div>,
          placement: 'auto',
          target: '#buttonsWrapper',
          title: WarehouseLocationTour.Step5.Title,
          disableBeacon: true,
          link: WarehouseLocationTour.Step5.DetailsLink,
        },
      ],
    }),
    [WarehouseLocationTour],
  );

  const warehouseTemplateTour = useMemo<TourType>(
    () => ({
      id: 'warehouseTemplateTour',
      title: WarehouseTemplateTour.Title,
      before: () => {
        dispatch(warehouseTemplateSettingsActions.setWarehouseTemplateModal({ visible: true }));
      },
      steps: [
        {
          content: <div>{WarehouseTemplateTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#warehouseTemplateTitle',
          title: WarehouseTemplateTour.Step1.Title,
          disableBeacon: true,
          link: WarehouseTemplateTour.Step1.DetailsLink,
        },
        {
          content: <div>{WarehouseTemplateTour.Step2.Text}</div>,
          placement: 'auto',
          target: '#warehouseTemplateName',
          title: WarehouseTemplateTour.Step2.Title,
          disableBeacon: true,
          link: WarehouseTemplateTour.Step2.DetailsLink,
        },
        {
          content: <div>{WarehouseTemplateTour.Step3.Text}</div>,
          placement: 'auto',
          target: '#templateItems',
          title: WarehouseTemplateTour.Step3.Title,
          disableBeacon: true,
          link: WarehouseTemplateTour.Step3.DetailsLink,
        },
        {
          content: <div>{WarehouseTemplateTour.Step4.Text}</div>,
          placement: 'auto',
          target: '#templateBlock',
          title: WarehouseTemplateTour.Step4.Title,
          disableBeacon: true,
          link: WarehouseTemplateTour.Step4.DetailsLink,
        },
        {
          content: <div>{WarehouseTemplateTour.Step5.Text}</div>,
          placement: 'auto',
          target: '#createTemplateItem',
          title: WarehouseTemplateTour.Step5.Title,
          disableBeacon: true,
          link: WarehouseTemplateTour.Step5.DetailsLink,
        },
        {
          content: <div>{WarehouseTemplateTour.Step6.Text}</div>,
          placement: 'auto',
          target: '#buttonsWrapper',
          title: WarehouseTemplateTour.Step6.Title,
          disableBeacon: true,
          link: WarehouseTemplateTour.Step6.DetailsLink,
        },
      ],
    }),
    [WarehouseTemplateTour],
  );

  const templateUnitTour = useMemo<TourType>(
    () => ({
      id: 'templateUnitTour',
      title: TemplateUnitTour.Title,
      before: () => {
        dispatch(warehouseTemplateSettingsActions.setWarehouseTemplateModal({ visible: true }));
        dispatch(warehouseTemplateSettingsActions.setNeedShowUnitModal({ visible: true }));
      },
      steps: [
        {
          content: <div>{TemplateUnitTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#templateUnitTitle',
          title: TemplateUnitTour.Step1.Title,
          disableBeacon: true,
          link: TemplateUnitTour.Step1.DetailsLink,
        },
        {
          content: <div>{TemplateUnitTour.Step2.Text}</div>,
          placement: 'auto',
          target: '#templateUnitName',
          title: TemplateUnitTour.Step2.Title,
          disableBeacon: true,
          link: TemplateUnitTour.Step2.DetailsLink,
        },
      ],
    }),
    [TemplateUnitTour],
  );

  return [pageTour, warehouseTypeTour, warehouseLocationTour, warehouseTemplateTour, templateUnitTour];
}
