import { RootState } from '@/Redux/RootReducer';
import { createSelector } from '@reduxjs/toolkit';

const archiveSection = (state: RootState) => state.onlineArchivePage.archiveSection;

const documentInfos = createSelector(archiveSection, (state) => state.documentInfos);

const focusedDocumentInfo = createSelector(archiveSection, (state) => state.focusedDocumentInfo);

const needShowModal = createSelector(archiveSection, (state) => state.needShowModal);

const needShowPostingModal = createSelector(archiveSection, (state) => state.needShowPostingModal);

export const archiveSectionSelectors = {
  documentInfos,
  focusedDocumentInfo,
  needShowModal,
  needShowPostingModal,
};
