import React, { useMemo } from 'react';
import { TourType } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';

export function useAmountAutomationSettings() {
  const {
    tours: {
      ShippingAutomationSettings: { Amount },
    },
  } = useLocalization();

  const pageTour = useMemo<TourType>(
    () => ({
      id: 'pageTour',
      title: Amount.Title,
      steps: [
        {
          content: <div>{Amount.Step1.Text}</div>,
          placement: 'auto',
          target: '#shippingAutomationTitle',
          title: Amount.Step1.Title,
          disableBeacon: true,
          link: Amount.Step1.DetailsLink,
        },
        {
          content: <div>{Amount.Step2.Text}</div>,
          placement: 'auto',
          target: '#amountAutomation',
          title: Amount.Step2.Title,
          disableBeacon: true,
          link: Amount.Step2.DetailsLink,
        },
        {
          content: <div>{Amount.Step3.Text}</div>,
          placement: 'auto',
          target: '#mpAccounts',
          title: Amount.Step3.Title,
          disableBeacon: true,
          link: Amount.Step3.DetailsLink,
          spotlightPadding: 10,
          styles: {
            spotlight: {
              marginTop: '-25px',
              marginLeft: '-5px',
              minHeight: '65px',
            },
          },
        },
        {
          content: <div>{Amount.Step4.Text}</div>,
          placement: 'auto',
          target: '#totalAmount',
          title: Amount.Step4.Title,
          disableBeacon: true,
          link: Amount.Step4.DetailsLink,
          spotlightPadding: 10,
          styles: {
            spotlight: {
              marginTop: '-25px',
              marginLeft: '-5px',
              minHeight: '65px',
            },
          },
        },
        {
          content: <div>{Amount.Step5.Text}</div>,
          placement: 'auto',
          target: '#shippingMethod',
          title: Amount.Step5.Title,
          disableBeacon: true,
          link: Amount.Step5.DetailsLink,
          spotlightPadding: 10,
          styles: {
            spotlight: {
              marginTop: '-25px',
              marginLeft: '-5px',
              minHeight: '65px',
            },
          },
        },
        {
          content: <div>{Amount.Step6.Text}</div>,
          placement: 'auto',
          target: '#userProductId',
          title: Amount.Step6.Title,
          disableBeacon: true,
          link: Amount.Step6.DetailsLink,
          spotlightPadding: 10,
          styles: {
            spotlight: {
              marginTop: '-25px',
              marginLeft: '-5px',
              minHeight: '65px',
            },
          },
        },
        {
          content: <div>{Amount.Step7.Text}</div>,
          placement: 'auto',
          target: '#countryZoneRecepient',
          title: Amount.Step7.Title,
          disableBeacon: true,
          link: Amount.Step7.DetailsLink,
          spotlightPadding: 10,
          styles: {
            spotlight: {
              marginTop: '-25px',
              marginLeft: '-5px',
              minHeight: '65px',
            },
          },
        },
        {
          content: <div>{Amount.Step8.Text}</div>,
          placement: 'auto',
          target: '#crudExtended',
          title: Amount.Step8.Title,
          disableBeacon: true,
          link: Amount.Step8.DetailsLink,
        },
        {
          content: <div>{Amount.Step9.Text}</div>,
          placement: 'auto',
          target: '#addAmountAutomationRule',
          title: Amount.Step9.Title,
          disableBeacon: true,
          link: Amount.Step9.DetailsLink,
        },
      ],
    }),
    [Amount],
  );

  return [pageTour];
}
