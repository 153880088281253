import { useLocalization } from '@/Hooks';
import { PagesRouting } from '@/Routing';
import { ModuleIcons } from '@/Static/IconsRes';
import { useMemo } from 'react';

export const useSideMenuItems = () => {
  const { modules } = useLocalization();

  return useMemo(() => {
    return [
      {
        title: modules.Dashboard,
        route: PagesRouting.MainPages.DashboardPage.Dashboard,
        settingsRoute: PagesRouting.MainPages.DashboardPage.Dashboard,
        icon: ModuleIcons.DashboardModule,
        settingsIcon: ModuleIcons.DashboardSettingsModule,
        classRoutes: [PagesRouting.MainPages.DashboardPage.Dashboard],
      },
      {
        title: modules.Sales,
        route: PagesRouting.MainPages.SalesPage.Sales,
        settingsRoute: PagesRouting.MainPages.SalesPage.SalesSettings,
        icon: ModuleIcons.SalesModule,
        settingsIcon: ModuleIcons.SalesSettingsModule,
        classRoutes: [PagesRouting.MainPages.SalesPage.Sales, PagesRouting.MainPages.SalesPage.SalesSettings],
        dataTestId: 'sales-page',
        moduleId: 'salesPage',
        moduleSettingsId: 'salesPageSettings',
      },
      {
        title: modules.Purchases,
        route: PagesRouting.MainPages.PurchasesPage,
        settingsRoute: PagesRouting.MainPages.PurchasesPage,
        icon: ModuleIcons.PurchasesModule,
        settingsIcon: ModuleIcons.PurchasesSettingsModule,
        classRoutes: [PagesRouting.MainPages.PurchasesPage],
      },
      {
        title: modules.Shop,
        route: PagesRouting.MainPages.ShopPage.Shop,
        settingsRoute: PagesRouting.MainPages.ShopPage.ShopSettings,
        icon: ModuleIcons.ShopModule,
        settingsIcon: ModuleIcons.ShopSettingsModule,
        classRoutes: [PagesRouting.MainPages.ShopPage.Shop, PagesRouting.MainPages.ShopPage.ShopSettings],
      },
      {
        title: modules.Messages,
        route: PagesRouting.MainPages.MessagesPage,
        settingsRoute: PagesRouting.MainPages.MessagesPage,
        icon: ModuleIcons.MessagesModule,
        settingsIcon: ModuleIcons.MessagesSettingsModule,
        classRoutes: [PagesRouting.MainPages.MessagesPage],
      },
      {
        title: modules.OnlineArchive,
        route: PagesRouting.MainPages.OnlineArchivePage.OnlineArchive,
        settingsRoute: PagesRouting.MainPages.OnlineArchivePage.OnlineArchiveSettings,
        icon: ModuleIcons.OnlineArchiveModule,
        settingsIcon: ModuleIcons.OnlineArchiveSettingsModule,
        classRoutes: [
          PagesRouting.MainPages.OnlineArchivePage.OnlineArchive,
          PagesRouting.MainPages.OnlineArchivePage.OnlineArchiveSettings,
          PagesRouting.MainPages.OnlineArchivePage.UploadTab,
          PagesRouting.MainPages.OnlineArchivePage.AllocateTab,
          PagesRouting.MainPages.OnlineArchivePage.ArchiveTab,
          PagesRouting.MainPages.OnlineArchivePage.ReportsTab,
        ],
        moduleId: 'onlineArchivePage',
        moduleSettingsId: 'onlineArchivePageSettings',
      },
      {
        title: modules.Listing,
        route: PagesRouting.MainPages.ListingPage,
        settingsRoute: PagesRouting.MainPages.ListingPage,
        icon: ModuleIcons.ListingModule,
        settingsIcon: ModuleIcons.ListingSettingsModule,
        classRoutes: [PagesRouting.MainPages.ListingPage],
      },
      {
        title: modules.Logistic,
        route: PagesRouting.MainPages.LogisticsPage.Logistics,
        settingsRoute: PagesRouting.MainPages.LogisticsPage.LogisticsSettings,
        icon: ModuleIcons.LogisticModule,
        settingsIcon: ModuleIcons.LogisticSettingsModule,
        classRoutes: [
          PagesRouting.MainPages.LogisticsPage.Logistics,
          PagesRouting.MainPages.LogisticsPage.LogisticsSettings,
        ],
      },
      {
        title: modules.Products,
        route: PagesRouting.MainPages.ProductsPage.Products,
        settingsRoute: PagesRouting.MainPages.ProductsPage.ProductsSettings,
        icon: ModuleIcons.ProductsModule,
        settingsIcon: ModuleIcons.ProductsSettingsModule,
        classRoutes: [
          PagesRouting.MainPages.ProductsPage.Products,
          PagesRouting.MainPages.ProductsPage.ProductsSettings,
        ],
      },
      {
        title: modules.Warehouse,
        route: PagesRouting.MainPages.WarehousePage.Warehouse,
        settingsRoute: PagesRouting.MainPages.WarehousePage.WarehouseSettings,
        icon: ModuleIcons.WarehouseModule,
        settingsIcon: ModuleIcons.WarehouseSettingsModule,
        classRoutes: [
          PagesRouting.MainPages.WarehousePage.Warehouse,
          PagesRouting.MainPages.WarehousePage.WarehouseSettings,
        ],
      },
      {
        title: modules.Customers,
        route: PagesRouting.MainPages.CustomersPage.Customers,
        settingsRoute: PagesRouting.MainPages.CustomersPage.CustomersSettings,
        icon: ModuleIcons.CustomersModule,
        settingsIcon: ModuleIcons.CustomersSettingsModule,
        classRoutes: [
          PagesRouting.MainPages.CustomersPage.Customers,
          PagesRouting.MainPages.CustomersPage.CustomersSettings,
        ],
      },
      {
        title: modules.Suppliers,
        route: PagesRouting.MainPages.SuppliersPage.Suppliers,
        settingsRoute: PagesRouting.MainPages.SuppliersPage.SuppliersSettings,
        icon: ModuleIcons.SuppliersModule,
        settingsIcon: ModuleIcons.SuppliersSettingsModule,
        classRoutes: [
          PagesRouting.MainPages.SuppliersPage.Suppliers,
          PagesRouting.MainPages.SuppliersPage.SuppliersSettings,
        ],
      },
      {
        title: modules.DesignService,
        route: PagesRouting.MainPages.DesignServicePage,
        settingsRoute: PagesRouting.MainPages.DesignServicePage,
        icon: ModuleIcons.DesignServiceModule,
        settingsIcon: ModuleIcons.DesignServiceSettingsModule,
        classRoutes: [PagesRouting.MainPages.DesignServicePage],
      },
      {
        title: modules.Accounting,
        route: PagesRouting.MainPages.AccountingPage.Accounting,
        settingsRoute: PagesRouting.MainPages.AccountingPage.AccountingSettings,
        icon: ModuleIcons.AccountingModule,
        settingsIcon: ModuleIcons.AccountingSettingsModule,
        classRoutes: [
          PagesRouting.MainPages.AccountingPage.Accounting,
          PagesRouting.MainPages.AccountingPage.AccountingSettings,
        ],
      },
    ];
  }, [modules]);
};
