import React from 'react';
import { useTours } from '@/Components/Tours/hooks/useTours';
import styles from './TourList.scss';
import { tourActions } from '@/Redux/Tours/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@/Components/Controls/Button/Button';
import { tourSelectors } from '@/Redux/Tours/selectors';
import cn from 'classnames';
import { useLocalization } from '@/Hooks';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';

export const TourList = () => {
  const dispatch = useDispatch();

  const {
    buttons: { Previous },
  } = useLocalization();

  const currentPageTours = useTours();
  const currentTour = useSelector(tourSelectors.currentTour);
  const activeStep = useSelector(tourSelectors.activeStep);
  const tourStarted = useSelector(tourSelectors.runTour);
  const liftSidebar = useSelector(tourSelectors.liftSidebar);

  const onSelectPage = () => {
    dispatch(tourActions.setSelectedRoute(''));
  };

  const onSelectStep = (tour: string, step: number) => {
    if (!tourStarted) {
      dispatch(tourActions.setRun({ run: true, tour }));
    }

    if (!currentTour || currentTour == tour) {
      dispatch(tourActions.setActiveStep(step));
    }
  };

  if (!currentPageTours.length) {
    return (
      <div>
        <Button
          theme={BUTTON_THEMES.PRIMARY}
          text={<>&larr; {Previous}</>}
          onClick={onSelectPage}
          className={styles.reset}
        />
      </div>
    );
  }

  return (
    <div>
      <Button
        theme={BUTTON_THEMES.PRIMARY}
        disabled={liftSidebar}
        text={<>&larr; {Previous}</>}
        onClick={onSelectPage}
        className={styles.reset}
      />

      <div className={styles.tourList}>
        {currentPageTours.map((tour) => (
          <div
            key={tour.title}
            className={cn(styles.tourItem, {
              [styles.active]: currentTour == tour.id && tourStarted,
              [styles.disabled]: currentTour != tour.id && tourStarted,
            })}
          >
            <div>{tour.title}</div>

            <div className={styles.tourSteps}>
              {tour.steps.map((step, idx) => (
                <div
                  key={idx}
                  onClick={() => onSelectStep(tour.id, idx)}
                  className={cn(styles.tourStep, { [styles.activeStep]: idx == activeStep })}
                >
                  {idx + 1}. {step.title}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
