import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { mpAccountsAsyncThunks } from './asyncThunks';
import { MpAccountModel } from '@/Models';
import { Nullable } from '@/Types';
import { MarketplaceType } from '@/Enums';
import { MpTypeModel } from '@/Models/MpAccount/MpTypeModel';
import { ModalDataType } from '@/Types/ModalDataType';

export type MarketplacesSettingsState = {
  mpAccounts: MpAccountModel[];
  mpTypes: MpTypeModel[];
  selectedAccount: Nullable<MpAccountModel>;
  selectedManualAccount: Nullable<MpAccountModel>;
  marketplaceModal: ModalDataType<MpAccountModel>;
};

const initialState: MarketplacesSettingsState = {
  mpAccounts: [],
  mpTypes: [],
  selectedAccount: null,
  selectedManualAccount: null,
  marketplaceModal: { visible: false },
};

const accountSettingsSlice = createSlice({
  name: 'accountSettings',
  initialState,
  reducers: {
    setMarketplaceModal(state, action: PayloadAction<ModalDataType<MpAccountModel>>) {
      state.marketplaceModal = action.payload;
    },
    selectAccount(state, action: PayloadAction<MpAccountModel | null>) {
      state.selectedAccount = action.payload;
    },
    selectManualAccount(state) {
      state.selectedAccount = state.mpAccounts.find((item) => item.marketplaceType === MarketplaceType.Manual) ?? null;
    },
    selectManualAccountForDefault(state) {
      state.selectedManualAccount =
        state.mpAccounts.find((item) => item.marketplaceType === MarketplaceType.Manual) ?? null;
    },
    setTokenExpirationTime(state, action: PayloadAction<{ accountId: string; tokenExpirationTime: string }>) {
      if (state.selectedAccount) {
        const { accountId, tokenExpirationTime } = action.payload;

        if (state.selectedAccount.id == Number(accountId)) {
          state.selectedAccount.tokenExpirationTime = tokenExpirationTime;
          state.selectedAccount.isCredentialsValid = true;
        }

        state.mpAccounts = state.mpAccounts.map((i) =>
          i.id === state.selectedAccount?.id ? state.selectedAccount : i,
        );
      } // if
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(mpAccountsAsyncThunks.getMpAccounts.fulfilled, (state, action) => {
        state.mpAccounts = action.payload.accounts;
        state.selectedAccount = action.payload.accounts.find((item) => item.id == state.selectedAccount?.id) ?? null;

        state.selectedManualAccount =
          action.payload.accounts.find((item) => item.marketplaceType === MarketplaceType.Manual) ?? null;
      })
      .addCase(mpAccountsAsyncThunks.getMpTypes.fulfilled, (state, action) => {
        state.mpTypes = action.payload.types;
      })
      .addCase(mpAccountsAsyncThunks.createMpAccount.fulfilled, (state, action) => {
        state.mpAccounts.push(action.payload.account);
        state.selectedAccount = action.payload.account;
      })
      .addCase(mpAccountsAsyncThunks.updateMpAccount.fulfilled, (state, action) => {
        state.mpAccounts = state.mpAccounts.map((i) => {
          if (i.id === action.payload.mpAccount.id) {
            return action.payload.mpAccount;
          } // if

          return i;
        });

        if (action.payload.mpAccount.marketplaceType === MarketplaceType.Manual) {
          state.selectedManualAccount = action.payload.mpAccount;
        } // if

        state.selectedAccount = action.payload.mpAccount;
      })
      .addCase(mpAccountsAsyncThunks.deleteMpAccount.fulfilled, (state, action) => {
        state.mpAccounts = state.mpAccounts.filter((item) => item.id !== action.payload.id);
        state.selectedAccount = null;
      })
      .addCase(mpAccountsAsyncThunks.updateMpCredentials.fulfilled, (state, action) => {
        const accountId = action.payload.accountId;
        const changedCredentials = action.payload.credentials;
        state.mpAccounts = state.mpAccounts.map((acc) => {
          return acc.id === accountId
            ? {
                ...acc,
                clientKey: changedCredentials.kauflandCredentials?.clientKey,
                secretKey: changedCredentials.kauflandCredentials?.secretKey,
              }
            : acc;
        });

        if (state.selectedAccount) {
          state.selectedAccount.marketplaceCredentials = changedCredentials;
        } // if
      });
  },
});

const { actions, reducer } = accountSettingsSlice;

export const marketplacesSettingsActions = actions;
export const marketplacesSettingsReducer = reducer;
export const marketplacesSettingsAsyncActions = mpAccountsAsyncThunks;
