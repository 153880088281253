import { UserWarehouseLocationModel } from 'src/Models/Warehouse/UserWarehouseLocationModel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { warehouseLocationAsyncActions } from './asyncActions';
import { ModalDataType } from '@/Types/ModalDataType';

export type WarehouseLocationSettingsState = {
  warehouseLocations: UserWarehouseLocationModel[];
  warehouseLocationModal: ModalDataType<UserWarehouseLocationModel>;
};

export const initialState: WarehouseLocationSettingsState = {
  warehouseLocations: [],
  warehouseLocationModal: { visible: false },
};

const warehouseLocationSlice = createSlice({
  name: 'userWarehouseLocationSettings',
  initialState,
  reducers: {
    setWarehouseLocationModal(state, action: PayloadAction<ModalDataType<UserWarehouseLocationModel>>) {
      state.warehouseLocationModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Locations
    builder.addCase(warehouseLocationAsyncActions.getUserWarehouseLocations.fulfilled, (state, action) => {
      state.warehouseLocations = action.payload.userWarehouseLocations;
    });
    builder.addCase(warehouseLocationAsyncActions.createUserWarehouseLocation.fulfilled, (state, action) => {
      state.warehouseLocations = [...state.warehouseLocations, action.payload.userWarehouseLocation];
    });
    builder.addCase(warehouseLocationAsyncActions.updateUserWarehouseLocation.fulfilled, (state, action) => {
      state.warehouseLocations = state.warehouseLocations.map((x) =>
        x.id === action.payload.userWarehouseLocation.id ? action.payload.userWarehouseLocation : x,
      );
    });
    builder.addCase(warehouseLocationAsyncActions.deleteUserWarehouseLocation.fulfilled, (state, action) => {
      state.warehouseLocations = state.warehouseLocations.filter((x) => x.id !== action.payload.id);
    });
  },
});

export const warehouseLocationSettingsActions = warehouseLocationSlice.actions;
export const warehouseLocationSettingsReducer = warehouseLocationSlice.reducer;
