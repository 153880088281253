import React, { useMemo } from 'react';
import { TourType } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';
import { useDispatch } from 'react-redux';
import { productsSettingsActions } from '@/Pages/Settings/modules/ProductsSettings/services/reducer';

export function useProductSettings() {
  const dispatch = useDispatch();

  const {
    tours: {
      ProductSettings: { PageTour, CategoryTour },
    },
  } = useLocalization();

  const pageTour = useMemo<TourType>(
    () => ({
      id: 'pageTour',
      title: PageTour.Title,
      steps: [
        {
          content: <div>{PageTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#productSettingsTitle',
          title: PageTour.Step1.Title,
          disableBeacon: true,
          link: PageTour.Step1.DetailsLink,
        },
        {
          content: <div>{PageTour.Step2.Text}</div>,
          placement: 'auto',
          target: '#categoryList',
          title: PageTour.Step2.Title,
          disableBeacon: true,
          link: PageTour.Step2.DetailsLink,
        },
        {
          content: <div>{PageTour.Step3.Text}</div>,
          placement: 'auto',
          target: '#categoryItem',
          title: PageTour.Step3.Title,
          disableBeacon: true,
          link: PageTour.Step3.DetailsLink,
          styles: {
            spotlight: {
              maxWidth: '800px',
              marginLeft: '20px',
              maxHeight: '35px',
            },
          },
        },
        {
          content: <div>{PageTour.Step4.Text}</div>,
          placement: 'auto',
          target: '#addNewCategory',
          title: PageTour.Step4.Title,
          disableBeacon: true,
          link: PageTour.Step4.DetailsLink,
        },
        {
          content: <div>{PageTour.Step5.Text}</div>,
          placement: 'auto',
          target: '#addItemButton',
          title: PageTour.Step5.Title,
          disableBeacon: true,
          link: PageTour.Step5.DetailsLink,
        },
        {
          content: <div>{PageTour.Step6.Text}</div>,
          placement: 'auto',
          target: '#crudExtended',
          title: PageTour.Step6.Title,
          disableBeacon: true,
          link: PageTour.Step6.DetailsLink,
        },
      ],
    }),
    [PageTour],
  );

  const categoryTour = useMemo<TourType>(
    () => ({
      id: 'categoryTour',
      title: CategoryTour.Title,
      before: () => {
        dispatch(productsSettingsActions.setCategoryModal({ visible: true }));
      },
      steps: [
        {
          content: <div>{CategoryTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#categoryTitle',
          title: CategoryTour.Step1.Title,
          disableBeacon: true,
          link: CategoryTour.Step1.DetailsLink,
        },
        {
          content: <div>{CategoryTour.Step2.Text}</div>,
          placement: 'auto',
          target: '#categoryName',
          title: CategoryTour.Step2.Title,
          disableBeacon: true,
          link: CategoryTour.Step2.DetailsLink,
        },
        {
          content: <div>{CategoryTour.Step3.Text}</div>,
          placement: 'auto',
          target: '#categoryLogo',
          title: CategoryTour.Step3.Title,
          disableBeacon: true,
          link: CategoryTour.Step3.DetailsLink,
        },
        {
          content: <div>{CategoryTour.Step4.Text}</div>,
          placement: 'auto',
          target: '#buttonsWrapper',
          title: CategoryTour.Step4.Title,
          disableBeacon: true,
          link: CategoryTour.Step4.DetailsLink,
        },
      ],
    }),
    [CategoryTour],
  );

  return [pageTour, categoryTour];
}
