import { Nullable } from '@/Types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UploadTemplateModel } from '@/Models/_Microservices/OnlineArchive/UploadTemplateModel';
import { ModalDataType } from '@/Types/ModalDataType';

export type OnlineArchiveSettingsState = {
  selectedTemplate: Nullable<UploadTemplateModel>;
  uploadTemplateModal: ModalDataType<UploadTemplateModel>;
};

const initialState: OnlineArchiveSettingsState = {
  selectedTemplate: null,
  uploadTemplateModal: { visible: false },
};

const onlineArchiveSettingsSlice = createSlice({
  name: 'onlineArchiveSettings',
  initialState,
  reducers: {
    selectUploadTemplate(state, action: PayloadAction<Nullable<UploadTemplateModel>>) {
      state.selectedTemplate = action.payload;
    },
    setUploadTemplateModal(state, action: PayloadAction<ModalDataType<UploadTemplateModel>>) {
      state.uploadTemplateModal = action.payload;
    },
  },
});

export const onlineArchiveSettingsActions = onlineArchiveSettingsSlice.actions;
export const onlineArchiveSettingsReducer = onlineArchiveSettingsSlice.reducer;
