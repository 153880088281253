import { NumberFormatHandler } from '@/Utils/services/NumberFormatHandler';

function insertParamsToString(str: string, params: (string | number)[]) {
  if (!str) return '';

  params.forEach((param) => {
    const startIndex = str.indexOf('{');
    const endIndex = str.indexOf('}');

    if (startIndex != -1 && endIndex != -1) {
      str = str.replace(str.substr(startIndex, endIndex - startIndex + 1), String(param));
    }
  });

  return str;
} // insertParamsToString

function defaultIfNullOrEmptyOrZero<T extends string | number>(
  value: string | null | undefined | number,
  defaultValue: T,
): T | string | number {
  return value === '' || value == null ? defaultValue : value;
} // defaultIfNullOrEmptyOrZero

function replaceAll(str: string, searchValues: string[], replaceValues: string[]) {
  for (let i = 0; i < searchValues.length; i++) {
    str = str.split(searchValues[i]).join(replaceValues[i]);
  } // for

  return str;
} // replaceAll

function isStringsEqual(str: string | undefined, otherStr: string | undefined) {
  return (str ?? '') === (otherStr ?? '');
}

function normalizeToNumberString(num: string) {
  if (!String(num).includes(',')) return num;

  const { format } = new Intl.NumberFormat(NumberFormatHandler.getInstance().getNumberFormatLocales());
  const [, decimalSign] = /^0(.)1$/.exec(format(0.1))!;

  return String(num)
    .replace(new RegExp(`[^${decimalSign}\\d]`, 'g'), '')
    .replace(decimalSign, '.');
}

function truncateText(text: string | undefined, maxLength: number) {
  if (!text) return '';

  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  } else {
    return text;
  }
}

export const stringUtils = {
  insertParamsToString,
  replaceAll,
  defaultIfNullOrEmpty: defaultIfNullOrEmptyOrZero,
  isStringsEqual,
  normalizeToNumberString,
  truncateText,
};
