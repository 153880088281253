import { createSelector } from '@reduxjs/toolkit';
import { settingsPageSelectors } from '@/Pages/Settings/services/selectors';
import { MarketplacesSettingsState } from './reducer';

const mpAccounts = createSelector(
  settingsPageSelectors.accountSettings,
  (accountSettings: MarketplacesSettingsState) => accountSettings.mpAccounts,
);

const mpTypes = createSelector(
  settingsPageSelectors.accountSettings,
  (accountSettings: MarketplacesSettingsState) => accountSettings.mpTypes,
);

const selectedMpAccount = createSelector(
  settingsPageSelectors.accountSettings,
  (accountSettings: MarketplacesSettingsState) => accountSettings.selectedAccount,
);

const selectedManualMpAccount = createSelector(
  settingsPageSelectors.accountSettings,
  (accountSettings: MarketplacesSettingsState) => accountSettings.selectedManualAccount,
);

const marketplaceModal = createSelector(
  settingsPageSelectors.accountSettings,
  (accountSettings: MarketplacesSettingsState) => accountSettings.marketplaceModal,
);

export const accountSettingsSelectors = {
  mpAccounts,
  mpTypes,
  selectedMpAccount,
  selectedManualMpAccount,
  marketplaceModal,
};
