import { createAsyncThunk } from '@reduxjs/toolkit';
import { LogisticsApi } from '@/Api/Logistics';
import { GetDetailedLogisticInfoResponse } from '@/Api/Logistics/GetDetailedLogisticInfoResponse';
import { GetDetailedLogisticInfoRequest } from '@/Api/Logistics/GetDetailedLogisticInfoRequest';
import { LogisticContractsApi } from '@/Api/LogisticContracts/LogisticContractsApi';
import { GetGroupedUserWarehouseResponse } from '@/Api/LogisticContracts/GetGroupedUserWarehouseResponse';
import { GetLogisticsByWarehouseResponse } from '@/Api/Logistics/GetLogisticsByWarehouseResponse';
import { GetLogisticsByWarehouseRequest } from '@/Api/Logistics/GetLogisticsByWarehouseRequest';
import { UpdateLogisticContractStateResponse } from '@/Api/LogisticContracts/UpdateLogisticContractStateResponse';
import { UpdateLogisticContractStateRequest } from '@/Api/LogisticContracts/UpdateLogisticContractStateRequest';
import {
  CreateDeutschePostInternetmarkeContractCommand,
  CreateDeutschePostInternetmarkeContractResponse,
  DeutschePostInternetmarkeContractsApi,
  GetDeutschePostInternetmarkeContractResponse,
  GetDeutschePostInternetmarkeContractStatusQuery,
  GetDeutschePostInternetmarkeContractStatusResponse,
  GetDeutschePostInternetmarkeFormatsResponse,
  UpdateDeutschePostInternetmarkeContractCommand,
  UpdateDeutschePostInternetmarkeContractResponse,
} from '@/Api/DeutschePostInternetmarkeContracts';
import {
  CheckLogisticContractRequest,
  CheckLogisticContractResponse,
  CreateLogisticContractRequest,
  CreateLogisticContractResponse,
  UpdateLogisticContractRequest,
  UpdateLogisticContractResponse,
} from '@/Api/LogisticContracts';
import { GetLogisticContractResponse } from '@/Api/LogisticContracts/GetLogisticContractResponse';
import { DeleteLogisticContractRequest } from '@/Api/LogisticContracts/DeleteLogisticContractRequest';
import { UpdateDeutschePostContractStateResponse } from '@/Api/DeutschePostInternetmarkeContracts/UpdateDeutschePostContractStateResponse';
import { UpdateDeutschePostContractStateRequest } from '@/Api/DeutschePostInternetmarkeContracts/UpdateDeutschePostContractStateRequest';

const getGroupedUserWarehouses = createAsyncThunk<GetGroupedUserWarehouseResponse>(
  'LogisticSettings/getGroupedUserWarehouses',
  () => {
    return LogisticContractsApi.getGroupedUserWarehouses();
  },
);

const getLogisticsByWarehouse = createAsyncThunk<GetLogisticsByWarehouseResponse, GetLogisticsByWarehouseRequest>(
  'LogisticSettings/getLogisticsByWarehouse',
  (request) => {
    return LogisticsApi.getLogisticsByWarehouse(request);
  },
);

const getDetailedLogisticInfo = createAsyncThunk<GetDetailedLogisticInfoResponse, GetDetailedLogisticInfoRequest>(
  'LogisticSettings/getDetailedLogisticInfo',
  (request) => {
    return LogisticsApi.getDetailedLogisticInfo(request);
  },
);

const getLogisticContract = createAsyncThunk<GetLogisticContractResponse, number>(
  'LogisticSettings/getLogisticContract',
  (request) => {
    return LogisticContractsApi.getLogisticContract(request);
  },
);

const getDeutschePostContract = createAsyncThunk<GetDeutschePostInternetmarkeContractResponse, number>(
  'LogisticSettings/getDeutschePostContract',
  (request) => {
    return DeutschePostInternetmarkeContractsApi.getContract(request);
  },
);

const getDeutschePostMarkeFormats = createAsyncThunk<GetDeutschePostInternetmarkeFormatsResponse>(
  'LogisticSettings/getDeutschePostMarkeFormats',
  () => {
    return DeutschePostInternetmarkeContractsApi.getMarkeFormats();
  },
);

const createLogisticContract = createAsyncThunk<CreateLogisticContractResponse, CreateLogisticContractRequest>(
  'LogisticContractSettings/createLogisticContract',
  async (request: CreateLogisticContractRequest) => {
    return await LogisticContractsApi.createLogisticContract(request);
  },
);

const createDeutschePostContract = createAsyncThunk<
  CreateDeutschePostInternetmarkeContractResponse,
  CreateDeutschePostInternetmarkeContractCommand
>(
  'LogisticContractSettings/createDeutschePostContract',
  async (request: CreateDeutschePostInternetmarkeContractCommand) => {
    return await DeutschePostInternetmarkeContractsApi.createContract(request);
  },
);

const updateLogisticContract = createAsyncThunk<UpdateLogisticContractResponse, UpdateLogisticContractRequest>(
  'LogisticContractSettings/updateLogisticContract',
  async (request: UpdateLogisticContractRequest) => {
    return await LogisticContractsApi.updateLogisticContract(request);
  },
);

const updateLogisticContractState = createAsyncThunk<
  UpdateLogisticContractStateResponse,
  UpdateLogisticContractStateRequest
>('LogisticSettings/updateLogisticContractState', (request) => {
  return LogisticContractsApi.updateLogisticContractState(request);
});

const updateDeutschePostContractState = createAsyncThunk<
  UpdateDeutschePostContractStateResponse,
  UpdateDeutschePostContractStateRequest
>('LogisticSettings/updateDeutschePostContractState', (request) => {
  return DeutschePostInternetmarkeContractsApi.updateContractState(request);
});

const updateDeutschePostContract = createAsyncThunk<
  UpdateDeutschePostInternetmarkeContractResponse,
  UpdateDeutschePostInternetmarkeContractCommand
>('LogisticSettings/updateDeutschePostContract', async (request: UpdateDeutschePostInternetmarkeContractCommand) => {
  return await DeutschePostInternetmarkeContractsApi.updateContract(request);
});

const checkContractConnection = createAsyncThunk<CheckLogisticContractResponse, CheckLogisticContractRequest>(
  'LogisticContractSettings/checkConnection',
  (request: CheckLogisticContractRequest) => {
    return LogisticContractsApi.checkConnection(request);
  },
);

const checkDeutschePostConnection = createAsyncThunk<
  GetDeutschePostInternetmarkeContractStatusResponse,
  GetDeutschePostInternetmarkeContractStatusQuery
>(
  'LogisticContractSettings/checkDeutschePostConnection',
  (request: GetDeutschePostInternetmarkeContractStatusQuery) => {
    return DeutschePostInternetmarkeContractsApi.checkConnection(request);
  },
);

const deleteLogisticContract = createAsyncThunk<number, DeleteLogisticContractRequest>(
  'LogisticContractSettings/deleteLogisticContract',
  (request) => {
    return LogisticContractsApi.deleteLogisticContract(request);
  },
);

const deleteDeutschePostContract = createAsyncThunk<number, number>(
  'LogisticContractSettings/deleteDeutschePostContract',
  (request: number) => {
    return DeutschePostInternetmarkeContractsApi.deleteContract(request);
  },
);

export const asyncActions = {
  getGroupedUserWarehouses,
  getDetailedLogisticInfo,
  getLogisticsByWarehouse,

  getLogisticContract,
  getDeutschePostContract,
  getDeutschePostMarkeFormats,

  createLogisticContract,
  createDeutschePostContract,

  updateLogisticContract,
  updateDeutschePostContract,
  updateLogisticContractState,
  updateDeutschePostContractState,

  checkContractConnection,
  checkDeutschePostConnection,

  deleteLogisticContract,
  deleteDeutschePostContract,
};
