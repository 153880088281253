import React, { useMemo } from 'react';
import { TourType } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';
import { marketplacesSettingsActions } from '@/Pages/Settings/modules/MarketplacesSettings/services/reducer';
import { useDispatch } from 'react-redux';

export function useMarketplaceSettings() {
  const dispatch = useDispatch();

  const {
    tours: {
      MarketplaceSettings: { Default, Account },
    },
  } = useLocalization();

  const pageTour = useMemo<TourType>(
    () => ({
      id: 'pageTour',
      title: Default.Title,
      steps: [
        {
          content: <div>{Default.Step1.Text}</div>,
          placement: 'auto',
          target: '#mpTitle',
          title: Default.Step1.Title,
          disableBeacon: true,
          link: Default.Step1.DetailsLink,
        },
        {
          content: <div>{Default.Step2.Text}</div>,
          placement: 'auto',
          target: '#mpTable',
          title: Default.Step2.Title,
          disableBeacon: true,
          link: Default.Step2.DetailsLink,
        },
        {
          content: <div>{Default.Step3.Text}</div>,
          placement: 'auto',
          target: '#crudExtended',
          title: Default.Step3.Title,
          disableBeacon: true,
          link: Default.Step3.DetailsLink,
        },
        {
          content: <div>{Default.Step4.Text}</div>,
          placement: 'auto',
          target: '.dx-toolbar-after',
          title: Default.Step4.Title,
          disableBeacon: true,
          link: Default.Step4.DetailsLink,
          spotlightPadding: 0,
          styles: {
            spotlight: {
              maxWidth: '500px',
            },
          },
        },
        {
          content: <div>{Default.Step5.Text}</div>,
          placement: 'auto',
          target: '.dx-dropdownmenu-button',
          title: Default.Step5.Title,
          disableBeacon: true,
          link: Default.Step5.DetailsLink,
        },
        {
          content: <div>{Default.Step6.Text}</div>,
          placement: 'auto',
          target: '#mpForm',
          title: Default.Step6.Title,
          disableBeacon: true,
          link: Default.Step6.DetailsLink,
        },
      ],
    }),
    [Default],
  );

  const accountTour = useMemo<TourType>(
    () => ({
      id: 'accountTour',
      title: Account.Title,
      before: () => {
        dispatch(marketplacesSettingsActions.setMarketplaceModal({ visible: true }));
      },
      steps: [
        {
          content: <div>{Account.Step1.Text}</div>,
          placement: 'auto',
          target: '#mpModalTitle',
          title: Account.Step1.Title,
          disableBeacon: true,
          link: Account.Step1.DetailsLink,
        },
        {
          content: <div>{Account.Step2.Text}</div>,
          placement: 'auto',
          target: '#mpModalFields',
          title: Account.Step2.Title,
          disableBeacon: true,
          link: Account.Step2.DetailsLink,
        },
        {
          content: <div>{Account.Step3.Text}</div>,
          placement: 'auto',
          target: '#merchantModalFulfillment',
          title: Account.Step3.Title,
          disableBeacon: true,
          link: Account.Step3.DetailsLink,
        },
        {
          content: <div>{Account.Step4.Text}</div>,
          placement: 'auto',
          target: '#mpModalFulfillment',
          title: Account.Step4.Title,
          disableBeacon: true,
          link: Account.Step4.DetailsLink,
        },
        {
          content: <div>{Account.Step5.Text}</div>,
          placement: 'auto',
          target: '#syncModalField',
          title: Account.Step5.Title,
          disableBeacon: true,
          link: Account.Step5.DetailsLink,
        },
        {
          content: <div>{Account.Step6.Text}</div>,
          placement: 'auto',
          target: '#settingModalFlags',
          title: Account.Step6.Title,
          disableBeacon: true,
          link: Account.Step6.DetailsLink,
        },
        {
          content: <div>{Account.Step7.Text}</div>,
          placement: 'auto',
          target: '#invoiceModalFlags',
          title: Account.Step7.Title,
          disableBeacon: true,
          link: Account.Step7.DetailsLink,
        },
      ],
    }),
    [Account],
  );

  return [pageTour, accountTour];
}
