import { combineReducers } from '@reduxjs/toolkit';
import { warehouseTypeSettingsReducer, WarehouseTypeSettingsState } from './warehouseTypes/reducer';
import { warehouseLocationSettingsReducer, WarehouseLocationSettingsState } from './warehouseLocations/reducer';
import { warehouseTemplateSettingsReducer, WarehouseTemplateSettingsState } from './warehouseTemplates/reducer';

export type WarehouseSettingsState = {
  userWarehouseTypeSettings: WarehouseTypeSettingsState;
  userWarehouseLocationSettings: WarehouseLocationSettingsState;
  userWarehouseTemplateSettings: WarehouseTemplateSettingsState;
};

export const warehouseSettingsPageReducer = combineReducers<WarehouseSettingsState>({
  userWarehouseTypeSettings: warehouseTypeSettingsReducer,
  userWarehouseLocationSettings: warehouseLocationSettingsReducer,
  userWarehouseTemplateSettings: warehouseTemplateSettingsReducer,
});
