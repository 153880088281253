import React, { useMemo } from 'react';
import { TourType } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';
import { userAccountSettingsActions } from '@/Pages/Settings/modules/AccountSettings/services/reducer';
import { useDispatch } from 'react-redux';

export function useAccountSettings() {
  const dispatch = useDispatch();

  const {
    tours: {
      AccountSettings: { Default, AccountSettings },
    },
  } = useLocalization();

  const pageTour = useMemo<TourType>(
    () => ({
      id: 'pageTour',
      title: Default.Title,
      steps: [
        {
          content: <div>{Default.Step1.Text}</div>,
          placement: 'auto',
          target: '.regionalSettingsBtn',
          title: Default.Step1.Title,
          disableBeacon: true,
          link: Default.Step1.DetailsLink,
        },
        {
          content: <div>{Default.Step2.Text}</div>,
          placement: 'auto',
          target: '.paymentMethodsBtn',
          title: Default.Step2.Title,
          disableBeacon: true,
          link: Default.Step2.DetailsLink,
        },
        {
          content: <div>{Default.Step3.Text}</div>,
          placement: 'auto',
          target: '.basicSettings',
          title: Default.Step3.Title,
          disableBeacon: true,
          link: Default.Step3.DetailsLink,
        },
        {
          content: <div>{Default.Step4.Text}</div>,
          placement: 'auto',
          target: '.additionalSettings',
          title: Default.Step4.Title,
          disableBeacon: true,
          link: Default.Step4.DetailsLink,
        },
        {
          content: <div>{Default.Step5.Text}</div>,
          placement: 'auto',
          target: '.additionalEditableSettings',
          title: Default.Step5.Title,
          disableBeacon: true,
          link: Default.Step5.DetailsLink,
        },
        {
          content: <div>{Default.Step6.Text}</div>,
          placement: 'auto',
          target: '.basicParameters',
          title: Default.Step6.Title,
          disableBeacon: true,
          link: Default.Step6.DetailsLink,
        },
      ],
    }),
    [Default],
  );

  const accountSettingsTour = useMemo<TourType>(
    () => ({
      id: 'accountSettingsTour',
      title: AccountSettings.Title,
      before: () => {
        dispatch(userAccountSettingsActions.setRegionalSettingModal(true));
      },
      steps: [
        {
          content: <div>{AccountSettings.Step1.Text}</div>,
          placement: 'auto',
          target: '.basicTimezoneSettings',
          title: AccountSettings.Step1.Title,
          disableBeacon: true,
          link: AccountSettings.Step1.DetailsLink,
        },
        {
          content: <div>{AccountSettings.Step2.Text}</div>,
          placement: 'auto',
          target: '.extraTimezoneSettings',
          title: AccountSettings.Step2.Title,
          disableBeacon: true,
          link: AccountSettings.Step2.DetailsLink,
        },
        {
          content: <div>{AccountSettings.Step3.Text}</div>,
          placement: 'auto',
          target: '.currencyTable',
          title: AccountSettings.Step3.Title,
          disableBeacon: true,
          link: AccountSettings.Step3.DetailsLink,
        },
      ],
    }),
    [AccountSettings],
  );

  return [pageTour, accountSettingsTour];
}
