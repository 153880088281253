import React, { useMemo } from 'react';
import { TourType } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';

export function useAddressBookSettings() {
  const {
    tours: {
      AddressBookSettings: { Default },
    },
  } = useLocalization();

  const pageTour = useMemo<TourType>(
    () => ({
      id: 'pageTour',
      title: Default.Title,
      steps: [
        {
          content: <div>{Default.Step1.Text}</div>,
          placement: 'auto',
          target: '#addressBookTitle',
          title: Default.Step1.Title,
          disableBeacon: true,
          link: Default.Step1.DetailsLink,
        },
        {
          content: <div>{Default.Step2.Text}</div>,
          placement: 'auto',
          target: '#addressBookTable',
          title: Default.Step2.Title,
          disableBeacon: true,
          link: Default.Step2.DetailsLink,
        },
        {
          content: <div>{Default.Step3.Text}</div>,
          placement: 'auto',
          target: '#crudExtended',
          title: Default.Step3.Title,
          disableBeacon: true,
          link: Default.Step3.DetailsLink,
        },
        {
          content: <div>{Default.Step4.Text}</div>,
          placement: 'auto',
          target: '.dx-toolbar-after',
          title: Default.Step4.Title,
          disableBeacon: true,
          link: Default.Step4.DetailsLink,
          spotlightPadding: 0,
          styles: {
            spotlight: {
              maxWidth: '500px',
            },
          },
        },
        {
          content: <div>{Default.Step5.Text}</div>,
          placement: 'auto',
          target: '.dx-dropdownmenu-button',
          title: Default.Step5.Title,
          disableBeacon: true,
          link: Default.Step5.DetailsLink,
        },
        {
          content: <div>{Default.Step6.Text}</div>,
          placement: 'auto',
          target: '#addressBookForm',
          title: Default.Step6.Title,
          disableBeacon: true,
          link: Default.Step6.DetailsLink,
        },
        {
          content: <div>{Default.Step7.Text}</div>,
          placement: 'auto',
          target: '#addressBookActions',
          title: Default.Step7.Title,
          disableBeacon: true,
          link: Default.Step7.DetailsLink,
        },
      ],
    }),
    [Default],
  );

  return [pageTour];
}
