import React, { useMemo } from 'react';
import { TourType } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';

export function useCategoryAutomationSettings() {
  const {
    tours: {
      ShippingAutomationSettings: { Category },
    },
  } = useLocalization();

  const pageTour = useMemo<TourType>(
    () => ({
      id: 'pageTour',
      title: Category.Title,
      steps: [
        {
          content: <div>{Category.Step1.Text}</div>,
          placement: 'auto',
          target: '#categoryAutomation',
          title: Category.Step1.Title,
          disableBeacon: true,
          link: Category.Step1.DetailsLink,
        },
        {
          content: <div>{Category.Step2.Text}</div>,
          placement: 'auto',
          target: '#categoryForm',
          title: Category.Step2.Title,
          disableBeacon: true,
          link: Category.Step2.DetailsLink,
          styles: {
            spotlight: {
              maxWidth: '930px',
            },
          },
        },
        {
          content: <div>{Category.Step3.Text}</div>,
          placement: 'auto',
          target: '#quantity',
          title: Category.Step3.Title,
          disableBeacon: true,
          link: Category.Step3.DetailsLink,
          styles: {
            spotlight: {
              marginTop: '-25px',
              minHeight: '60px',
            },
          },
        },
        {
          content: <div>{Category.Step4.Text}</div>,
          placement: 'auto',
          target: '#shippingMethod',
          title: Category.Step4.Title,
          disableBeacon: true,
          link: Category.Step4.DetailsLink,
          styles: {
            spotlight: {
              marginTop: '-25px',
              minHeight: '60px',
            },
          },
        },
        {
          content: <div>{Category.Step5.Text}</div>,
          placement: 'auto',
          target: '#productId',
          title: Category.Step5.Title,
          disableBeacon: true,
          link: Category.Step5.DetailsLink,
          styles: {
            spotlight: {
              marginTop: '-25px',
              minHeight: '60px',
            },
          },
        },
        {
          content: <div>{Category.Step6.Text}</div>,
          placement: 'auto',
          target: '#countryZoneRecepient',
          title: Category.Step6.Title,
          disableBeacon: true,
          link: Category.Step6.DetailsLink,
          styles: {
            spotlight: {
              marginTop: '-25px',
              minHeight: '60px',
            },
          },
        },
        {
          content: <div>{Category.Step7.Text}</div>,
          placement: 'auto',
          target: '#addCategoryPackagingForm',
          title: Category.Step7.Title,
          disableBeacon: true,
          link: Category.Step7.DetailsLink,
        },
        {
          content: <div>{Category.Step8.Text}</div>,
          placement: 'auto',
          target: '#addCategoryAutomationRule',
          title: Category.Step8.Title,
          disableBeacon: true,
          link: Category.Step8.DetailsLink,
        },
      ],
    }),
    [Category],
  );

  return [pageTour];
}
