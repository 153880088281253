import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserProductCategoryModel } from '@/Models';
import { productSettingsAsyncActions } from './asyncActions';
import { ModalDataType } from '@/Types/ModalDataType';

export type ProductsSettingsState = {
  categories: UserProductCategoryModel[];
  categoryModal: ModalDataType<UserProductCategoryModel>;
};

const initialState: ProductsSettingsState = {
  categories: [],
  categoryModal: { visible: false },
};

const productsSettingsSlice = createSlice({
  name: 'productsSettings',
  initialState,
  reducers: {
    setCategoryModal(state, action: PayloadAction<ModalDataType<UserProductCategoryModel>>) {
      state.categoryModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Locations
    builder.addCase(productSettingsAsyncActions.getProductCategories.fulfilled, (state, action) => {
      state.categories = action.payload.categories;
    });
  },
});

export const productsSettingsReducer = productsSettingsSlice.reducer;
export const productsSettingsActions = productsSettingsSlice.actions;
