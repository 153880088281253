import { UserWarehouseTemplateModel } from 'src/Models/Warehouse/UserWarehouseTemplateModel';
import { UserWarehouseTemplateItemModel } from 'src/Models/Warehouse/UserWarehouseTemplateItemModel';
import { Nullable } from 'src/Types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { warehouseTemplateAsyncActions } from './asyncActions';
import { ModalDataType } from '@/Types/ModalDataType';

export type WarehouseTemplateSettingsState = {
  templates: UserWarehouseTemplateModel[];
  items: UserWarehouseTemplateItemModel[];
  needDeleteUnitId: Nullable<number>;
  warehouseTemplateModal: ModalDataType<UserWarehouseTemplateModel>;
  needShowUnitModal: ModalDataType<UserWarehouseTemplateItemModel>;
};

const initialState: WarehouseTemplateSettingsState = {
  templates: [],
  items: [],
  needDeleteUnitId: null,
  warehouseTemplateModal: { visible: false },
  needShowUnitModal: { visible: false },
};

const warehouseTemplateSlice = createSlice({
  name: 'userWarehouseTemplateSettings',
  initialState,
  reducers: {
    setWarehouseTemplateModal(state, action: PayloadAction<ModalDataType<UserWarehouseTemplateModel>>) {
      state.warehouseTemplateModal = action.payload;
    },
    setNeedDeleteUnitId: (state, action: PayloadAction<Nullable<number>>) => {
      state.needDeleteUnitId = action.payload;
    },
    setNeedShowUnitModal: (
      state,
      action: PayloadAction<{ visible: boolean; data?: UserWarehouseTemplateItemModel }>,
    ) => {
      state.needShowUnitModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(warehouseTemplateAsyncActions.getUserWarehouseTemplates.fulfilled, (state, action) => {
      state.templates = action.payload.templates;
    });
    builder.addCase(warehouseTemplateAsyncActions.createUserWarehouseTemplate.fulfilled, (state, action) => {
      state.templates = [action.payload.template, ...state.templates];
    });
    builder.addCase(warehouseTemplateAsyncActions.updateUserWarehouseTemplate.fulfilled, (state, action) => {
      const updatedItem = action.payload.template;
      state.templates = state.templates.map((x) => (x.id === updatedItem.id ? updatedItem : x));
    });
    builder.addCase(warehouseTemplateAsyncActions.deleteUserWarehouseTemplate.fulfilled, (state, action) => {
      state.templates = state.templates.filter((x) => x.id !== action.payload.templateId);
    });

    builder.addCase(warehouseTemplateAsyncActions.createUserWarehouseTemplateItem.fulfilled, (state, action) => {
      state.items = [action.payload.item, ...state.items];
    });
    builder.addCase(warehouseTemplateAsyncActions.updateUserWarehouseTemplateItem.fulfilled, (state, action) => {
      const updatedItem = action.payload.item;

      state.items = state.items.map((x) => (x.id === updatedItem.id ? updatedItem : x));
    });
    builder.addCase(warehouseTemplateAsyncActions.getUserWarehouseTemplateItems.fulfilled, (state, action) => {
      state.items = action.payload.items;
    });
    builder.addCase(warehouseTemplateAsyncActions.deleteUserWarehouseTemplateItem.fulfilled, (state, action) => {
      state.items = state.items.filter((x) => x.id !== action.payload.itemId);
    });
  },
});

export const warehouseTemplateSettingsActions = warehouseTemplateSlice.actions;
export const warehouseTemplateSettingsReducer = warehouseTemplateSlice.reducer;
