import { DateFormatType, InvoiceType, OrderStatusFilter } from '@/Enums';
import { OrderItemModel } from '@/Models';
import { Nullable, OrderFilterModel, OrderItemProductShortTableRow, OrderTableRow } from '@/Types';
import { DateRangeParamType } from '@/Api';
import moment from 'moment/moment';

function calculateProductPrice(productItem: OrderItemProductShortTableRow, invoiceType: InvoiceType): number {
  return +(
    productItem.quantity *
    (invoiceType === InvoiceType.GrossInvoice ? Number(productItem.priceBrutto) : Number(productItem.priceNetto))
  ).toFixed(2);
}

function calculateOrderItemPrice(invoiceType: InvoiceType, orderItem: OrderItemModel): number {
  const price = invoiceType === InvoiceType.GrossInvoice ? Number(orderItem.priceBrutto) : Number(orderItem.priceNetto);
  return orderItem.quantity * price;
}

function calculatePriceBrutto(priceNetto: number, vat: number): number {
  return +(priceNetto * (1 + vat / 100)).toFixed(2);
}

function calculatePriceNetto(priceBrutto: number, vat: number): number {
  return +(priceBrutto / (1 + vat / 100)).toFixed(2);
}

function calculateShippingPrice(order: Nullable<OrderTableRow>, invoiceType: InvoiceType) {
  if (!order) return 0;
  switch (invoiceType) {
    case InvoiceType.GrossInvoice:
      return order.shippingPrice;
    case InvoiceType.NetInvoice:
      return calculatePriceNetto(order.shippingPrice, order.orderItems[0].salesTaxPercent);
    default:
      throw 'InvoiceType out of range';
  }
}

function getInvoiceNumbers(order: Nullable<OrderTableRow>): string[] {
  return order?.invoices?.map((inv) => inv.number) ?? [];
}

function getCancelInvoiceNumbers(order: Nullable<OrderTableRow>): string[] {
  return (
    order?.invoices
      ?.map((inv) => inv.orderCancelInvoice)
      .filter((cancelInv) => cancelInv)
      .map((cancelInvoice) => cancelInvoice!.number) ?? []
  );
}

function getDefaultFilters(): OrderFilterModel {
  return {
    mpAccountIds: [],
    orderStatusFilter: OrderStatusFilter.NotShippedAll,
    dateRange: {
      rangeType: DateRangeParamType.CreationTime,
      timeFrom: moment().subtract(30, 'days').startOf('day').format(DateFormatType.LOCAL_DATE_TIME),
      timeTo: moment().endOf('day').format(DateFormatType.LOCAL_DATE_TIME),
    },
  };
}

export const salesUtils = {
  calculateProductPrice,
  calculateOrderItemPrice,
  calculateShippingPrice,
  getInvoiceNumbers,
  getCancelInvoiceNumbers,
  calculatePriceBrutto,
  calculatePriceNetto,
  getDefaultFilters,
};
