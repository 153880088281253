import React, { useRef, useState } from 'react';
import { ClassNameProps, Pair } from '@/Types';
import { TreeView as DevextremeTreeView } from 'devextreme-react/tree-view';
import styles from './TreeView.scss';
import cn from 'classnames';
import { useLocalization } from '@/Hooks';
import { treeViewUtils } from '@/Utils';

type Props<T extends string | number> = {
  /**
   * Pass this prop with useMemo
   */
  items: TreeItemType<T>[];
  onItemClick?: (item: TreeItemType) => void;
  onBlur?: () => void;
  selectionMode?: 'single' | 'multiple';
  checkboxesMode?: 'none' | 'normal' | 'selectAll';
  onSelectionChanged?: (ev: { ids: (string | number)[]; nodes: TreeItemType<T>[] }) => void;
  width?: number | string;
  hoverStateEnabled?: boolean;
  onItemContextMenu?: (item: TreeItemType) => void;
  onItemExpanded?: (item: TreeItemType) => void;
  onItemCollapsed?: (item: TreeItemType) => void;
  treeViewRef?: React.MutableRefObject<DevextremeTreeView<T> | undefined>;
  readOnly?: boolean;
  id?: string;
  needSearch?: boolean;
  selectOnlyLastLevel?: boolean;
} & ClassNameProps;

export type TreeItemType<T extends string | number = string> = {
  items?: TreeItemType<T>[];
  expanded?: boolean;
  parent?: TreeItemType<T>;
  className?: string;
} & Pair<T>;

const TreeViewItem = (params: TreeItemType) => {
  return <div className={params.className}>{params.value}</div>;
};

export function TreeView<T extends string | number = string>({
  items,
  className,
  onItemClick,
  onBlur,
  checkboxesMode = 'none',
  selectionMode = 'single',
  width = 200,
  onSelectionChanged,
  hoverStateEnabled = false,
  treeViewRef,
  readOnly,
  id,
  onItemContextMenu,
  onItemExpanded,
  onItemCollapsed,
  needSearch = false,
  selectOnlyLastLevel = false,
}: Props<T>) {
  const [tooltip, setTooltip] = useState('');

  const {
    common: { SelectAll },
  } = useLocalization();

  const treeViewSelectRef = useRef();

  if (!treeViewRef) {
    treeViewRef = treeViewSelectRef;
  }

  return (
    <DevextremeTreeView
      id={id}
      //scrollDirection={''}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      ref={treeViewRef}
      selectAllText={SelectAll}
      itemRender={TreeViewItem}
      onItemExpanded={(ev) => {
        onItemExpanded?.(ev.itemData as TreeItemType);
      }}
      onItemCollapsed={(ev) => {
        onItemCollapsed?.(ev.itemData as TreeItemType);
      }}
      showCheckBoxesMode={checkboxesMode}
      className={cn(styles.treeView, className)}
      items={items}
      displayExpr={'value'}
      keyExpr={'key'}
      width={width}
      selectByClick={!readOnly}
      selectionMode={selectionMode}
      selectNodesRecursive={!selectOnlyLastLevel}
      hoverStateEnabled={hoverStateEnabled}
      hint={tooltip}
      searchEnabled={needSearch}
      searchMode={'contains'}
      searchTimeout={1000}
      onFocusOut={onBlur}
      onItemContextMenu={(ev) => {
        onItemContextMenu?.(ev.itemData as TreeItemType);
        if (treeViewRef && ev.itemData) {
          treeViewRef.current?.instance.selectItem(ev.itemData);
        }
      }}
      onContentReady={(ev) => {
        if (selectOnlyLastLevel) {
          const flattenedItems = treeViewUtils.getFlattenedTreeViewItems<any>(items);
          flattenedItems.forEach((item) => {
            if (item.items && item.items?.length > 0) {
              const node = ev.component?.element().querySelector(`[data-item-id="${item.key}"]`);
              (node?.childNodes[0] as HTMLDivElement).style.display = 'none';
              (node?.childNodes[1] as HTMLDivElement).style.paddingLeft = '5px';
              (node?.childNodes[1] as HTMLDivElement).style.pointerEvents = 'none';
            }
          });
        }

        const nodes = ev.component?.element().querySelectorAll('.dx-treeview-item') || [];
        nodes.forEach((el: Element) => {
          el.classList.add(styles.treeViewItem);
          el.addEventListener('mouseenter', () => {
            setTooltip(el.querySelector('span')?.innerText ?? '');
          });
        });
      }}
      onSelectionChanged={(e) => {
        onSelectionChanged?.({
          ids: e.component?.getSelectedNodeKeys() || [],
          nodes: e.component
            ?.getSelectedNodes()
            ?.filter((el) => el.children?.length === 0)
            .map((el): TreeItemType<T> => treeViewUtils.convertNodeToTreeViewItem(el)),
        });
      }}
      onItemClick={(e) => {
        onItemClick?.(e.itemData as TreeItemType);
      }}
    />
  );
}
