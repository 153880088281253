import React, { useMemo } from 'react';
import { TourType } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';

export function useOnlineArchiveReports() {
  const {
    tours: {
      OnlineArchive: { ReportTour },
    },
  } = useLocalization();

  const pageTour = useMemo<TourType>(
    () => ({
      id: 'pageTour',
      title: ReportTour.Title,
      steps: [
        {
          content: <div>{ReportTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#reports',
          title: ReportTour.Step1.Title,
          disableBeacon: true,
          link: ReportTour.Step1.DetailsLink,
        },
        {
          content: <div>{ReportTour.Step2.Text}</div>,
          placement: 'auto',
          target: '#reportInfo',
          title: ReportTour.Step2.Title,
          disableBeacon: true,
          link: ReportTour.Step2.DetailsLink,
        },
        {
          content: <div>{ReportTour.Step3.Text}</div>,
          placement: 'auto',
          target: '#createReport',
          title: ReportTour.Step3.Title,
          disableBeacon: true,
          link: ReportTour.Step3.DetailsLink,
        },
        {
          content: <div>{ReportTour.Step4.Text}</div>,
          placement: 'auto',
          target: '#createReportWithService',
          title: ReportTour.Step4.Title,
          disableBeacon: true,
          link: ReportTour.Step4.DetailsLink,
        },
        {
          content: <div>{ReportTour.Step5.Text}</div>,
          placement: 'top',
          target: '#reportsTable',
          title: ReportTour.Step5.Title,
          disableBeacon: true,
          link: ReportTour.Step5.DetailsLink,
        },
        {
          content: <div>{ReportTour.Step6.Text}</div>,
          placement: 'auto',
          target: '.dx-toolbar-after',
          title: ReportTour.Step6.Title,
          disableBeacon: true,
          link: ReportTour.Step6.DetailsLink,
          spotlightPadding: 0,
          styles: {
            spotlight: {
              maxWidth: '500px',
            },
          },
        },
        {
          content: <div>{ReportTour.Step7.Text}</div>,
          placement: 'auto',
          target: '.dx-dropdownmenu-button',
          title: ReportTour.Step7.Title,
          disableBeacon: true,
          link: ReportTour.Step7.DetailsLink,
        },
        {
          content: <div>{ReportTour.Step8.Text}</div>,
          placement: 'auto',
          target: '#adScreen',
          title: ReportTour.Step8.Title,
          disableBeacon: true,
          link: ReportTour.Step8.DetailsLink,
        },
      ],
    }),
    [ReportTour],
  );

  return [pageTour];
}
