import React, { useMemo } from 'react';
import { TourType } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';

export function useAllocateSettings() {
  const {
    tours: {
      OnlineArchiveSettings: { Allocate },
    },
  } = useLocalization();

  const pageTour = useMemo<TourType>(
    () => ({
      id: 'pageTour',
      title: Allocate.Title,
      steps: [
        {
          content: <div>{Allocate.Step1.Text}</div>,
          placement: 'auto',
          target: '#allocateCategories',
          title: Allocate.Step1.Title,
          disableBeacon: true,
          link: Allocate.Step1.DetailsLink,
        },
        {
          content: <div>{Allocate.Step2.Text}</div>,
          placement: 'bottom',
          target: '#categoryList',
          title: Allocate.Step2.Title,
          disableBeacon: true,
          link: Allocate.Step2.DetailsLink,
          styles: {
            spotlight: {
              maxWidth: '200px',
            },
          },
        },
        {
          content: <div>{Allocate.Step3.Text}</div>,
          placement: 'auto',
          target: '#crudExtended',
          title: Allocate.Step3.Title,
          disableBeacon: true,
          link: Allocate.Step3.DetailsLink,
        },
      ],
    }),
    [Allocate],
  );

  return [pageTour];
}
