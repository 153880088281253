import React, { useMemo } from 'react';
import { TourType } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';
import { emailTemplatesActions } from '@/Pages/Settings/modules/EmailTemplatesSettings/services/reducer';
import { useDispatch } from 'react-redux';

export function useEmailTemplateSettings() {
  const dispatch = useDispatch();

  const {
    tours: {
      EmailTemplateSettings: { Default, Template },
    },
  } = useLocalization();

  const pageTour = useMemo<TourType>(
    () => ({
      id: 'pageTour',
      title: Default.Title,
      steps: [
        {
          content: <div>{Default.Step1.Text}</div>,
          placement: 'auto',
          target: '#templateTitle',
          title: Default.Step1.Title,
          disableBeacon: true,
          link: Default.Step1.DetailsLink,
        },
        {
          content: <div>{Default.Step2.Text}</div>,
          placement: 'auto',
          target: '#templatesTable',
          title: Default.Step2.Title,
          disableBeacon: true,
          link: Default.Step2.DetailsLink,
        },
        {
          content: <div>{Default.Step3.Text}</div>,
          placement: 'auto',
          target: '#crudExtended',
          title: Default.Step3.Title,
          disableBeacon: true,
          link: Default.Step3.DetailsLink,
        },
        {
          content: <div>{Default.Step4.Text}</div>,
          placement: 'auto',
          target: '.dx-toolbar-after',
          title: Default.Step4.Title,
          disableBeacon: true,
          link: Default.Step4.DetailsLink,
          spotlightPadding: 0,
          styles: {
            spotlight: {
              maxWidth: '500px',
            },
          },
        },
        {
          content: <div>{Default.Step5.Text}</div>,
          placement: 'auto',
          target: '.dx-dropdownmenu-button',
          title: Default.Step5.Title,
          disableBeacon: true,
          link: Default.Step5.DetailsLink,
        },
      ],
    }),
    [Default],
  );

  const templateTour = useMemo<TourType>(
    () => ({
      id: 'templateTour',
      title: Template.Title,
      before: () => {
        dispatch(emailTemplatesActions.setModalData({ visible: true }));
      },
      steps: [
        {
          content: <div>{Template.Step1.Text}</div>,
          placement: 'auto',
          target: '#templateName',
          title: Template.Step1.Title,
          disableBeacon: true,
          link: Template.Step1.DetailsLink,
        },
        {
          content: <div>{Template.Step2.Text}</div>,
          placement: 'auto',
          target: '#emailData',
          title: Template.Step2.Title,
          disableBeacon: true,
          link: Template.Step2.DetailsLink,
        },
        {
          content: <div>{Template.Step3.Text}</div>,
          placement: 'auto',
          target: '#sendingEmail',
          title: Template.Step3.Title,
          disableBeacon: true,
          link: Template.Step3.DetailsLink,
        },
        {
          content: <div>{Template.Step4.Text}</div>,
          placement: 'auto',
          target: '#attachFiles',
          title: Template.Step4.Title,
          disableBeacon: true,
          link: Template.Step4.DetailsLink,
        },
        {
          content: <div>{Template.Step5.Text}</div>,
          placement: 'auto',
          target: '#compartment',
          title: Template.Step5.Title,
          disableBeacon: true,
          link: Template.Step5.DetailsLink,
        },
        {
          content: <div>{Template.Step6.Text}</div>,
          placement: 'auto',
          target: '#templateAutomation',
          title: Template.Step6.Title,
          disableBeacon: true,
          link: Template.Step6.DetailsLink,
        },
        {
          content: <div>{Template.Step7.Text}</div>,
          placement: 'left',
          target: '#dxReportDesignerId',
          title: Template.Step7.Title,
          disableBeacon: true,
          link: Template.Step7.DetailsLink,
        },
      ],
    }),
    [Template],
  );

  return [pageTour, templateTour];
}
