import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '@/Hooks/useFormWrapper';
import { AppDispatch } from '@/Redux/ConfigureStore';
import { ChangePasswordResponse, CheckEmailTokenResponse } from '@/Api/AppAuth';
import { globalHistory } from '@/GlobalHistory';
import { PagesRouting } from '@/Routing';
import qs from 'qs';
import { EmailType } from '@/Enums';
import { withAuthorizationModules } from '@/Hocs';
import { useLocalization } from '@/Hooks';
import { useRequired } from '@/Hooks/Validation';
import { userActionsAsync, userSelectors } from '@/Redux/User';
import styles from './ChangePassword.scss';
import { Toasts } from '@/Components/Toast/Toast';
import { SignInput } from '@/Components/Controls/SignInputs/SignInput';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import { Button } from '@/Components/Controls/Button/Button';
import { LoaderSpinner } from '@/Components/LoaderSpinner/LoaderSpinner';
import { useShouldBePassword } from '@/Hooks/Validation/useShouldBePassword';

type QueryStringParams = {
  code: string;
};

type FormData = {
  password: string;
  confirmPassword: string;
};

function ChangePasswordPageComponent(): React.ReactElement {
  const dispatch: AppDispatch = useDispatch();

  const location = window.location.href;
  const { code } = qs.parse(location.split('?')[1]) as QueryStringParams;

  const user = useSelector(userSelectors.currentUser);

  const {
    register,
    trigger,
    formState: { isDirty, isValid, errors },
    getValues,
    setValue,
    watch,
  } = useForm<FormData>({ mode: 'onChange' });
  const required = useRequired();
  const valueMustBePassword = useShouldBePassword();

  const {
    notifications: { PasswordChangeSuccess },
    authorizationPage: { Buttons, ChangePassword },
  } = useLocalization();

  const [loader, setLoader] = useState<{ message?: string; isLoading?: boolean }>();

  // Reset confirm passwrod field if password changed
  useEffect(() => {
    setValue('confirmPassword', '');
  }, [watch('password')]);

  // Cheking user email token
  useEffect(() => {
    if (code) {
      setLoader({ message: ChangePassword.CheckingTheLink, isLoading: true });

      setTimeout(
        () =>
          dispatch(userActionsAsync.checkEmailToken({ code: code, emailType: EmailType.ResetPassword })).then(
            (response) => {
              const { errors } = response.payload as CheckEmailTokenResponse;

              if (errors?.length) {
                setLoader({ isLoading: false });
                return;
              } // if

              setLoader({});
            },
          ),
        2000,
      );
    } // if
  }, []);

  // Form submit method
  const onSubmit = () => {
    trigger();

    if (isValid) {
      const { password } = getValues();

      dispatch(userActionsAsync.changeUserPassword({ password: password, code: code })).then((response) => {
        const { errors } = response.payload as ChangePasswordResponse;

        if (!errors) {
          Toasts.showSuccess({ title: PasswordChangeSuccess.title, text: PasswordChangeSuccess.text });

          setLoader({ message: ChangePassword.RedirectingToLogin, isLoading: true });
          setTimeout(() => globalHistory.push(PagesRouting.AuthorizationPages.LoginPage), 4000);
        } // if
      });
    } // if
  };

  if (loader?.isLoading) {
    return (
      <div className={styles.authorizationInfo}>
        <LoaderSpinner visible={loader.isLoading} type="Oval" text={loader.message} />
      </div>
    );
  }

  if (!user) {
    return (
      <div className={styles.authorizationInfo}>
        <div className={styles.authorizationInfoText}>
          <p>{ChangePassword.SomethingIsWrongWithTheLinkPleaseTryToResetYourPasswordAgain}</p>
        </div>

        <Button
          text={Buttons.GoToLogin}
          theme={BUTTON_THEMES.SUCCESS}
          onClick={() => globalHistory.push(PagesRouting.AuthorizationPages.LoginPage)}
        />
      </div>
    );
  }

  return (
    <div className={styles.authorizationInfo}>
      <div className={styles.authorizationInfoText}>
        <p>{ChangePassword.PleaseEnterYourNewPasswordBelow}</p>
      </div>

      <div className={styles.authorizationInfoInputsWrapper}>
        <SignInput<FormData>
          name={'password'}
          type={'password'}
          needShowCheckbox={true}
          register={register}
          rules={{
            ...required,
            ...valueMustBePassword,
          }}
          placeholder={ChangePassword.Password}
          error={errors}
        />

        <SignInput<FormData>
          name={'confirmPassword'}
          type={'password'}
          register={register}
          rules={{
            ...required,
            validate: (value) => value === watch('password') || ChangePassword.ThePasswordsDoNotMatch,
          }}
          placeholder={ChangePassword.ConfirmPassword}
          error={errors}
        />
      </div>

      <Button
        text={Buttons.Continue}
        theme={BUTTON_THEMES.SUCCESS}
        disabled={!isDirty || !isValid}
        onClick={() => onSubmit()}
      />
    </div>
  );
}

export const ChangePasswordPage = withAuthorizationModules(ChangePasswordPageComponent);
