import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { asyncActions } from './asyncActions';
import { WarehouseLocationsGroupedModel } from '@/Models/Logistics/LogisticModel';
import { LogisticTypeKeys } from '@/Enums';
import { ModalDataType } from '@/Types/ModalDataType';
import { LogisticInfoModel } from '@/Models/Logistics/LogisticInfoModel';
import { LogisticShortModel } from '@/Models/Logistics/LogisticShortModel';

type ContractModalType = {
  visible: boolean;
  logisticType?: LogisticTypeKeys;
  contractId?: number;
  locationId?: number;
  mandatId?: number;
  contract?: any;
};

type DeleteModalType = {
  name: string;
  callback: () => void;
};

type ConnectModalType = {
  locationId: number;
  mandatId: number;
};

type ConfirmConnectModal = {
  contract: any;
  isDeutschePost: boolean;
};

export type LogisticSettingsState = {
  groupedLocations: WarehouseLocationsGroupedModel[];
  groupedLogistics: LogisticShortModel[];
  connectModal: ModalDataType<ConnectModalType>;
  logisticInfoModal: ModalDataType<LogisticInfoModel>;
  logisticContractModal: ContractModalType;
  deleteContractModal: ModalDataType<DeleteModalType>;
  confirmConnectModal: ModalDataType<ConfirmConnectModal>;
  contractActiveStep: number;
};

const initialState: LogisticSettingsState = {
  groupedLocations: [],
  groupedLogistics: [],
  connectModal: { visible: false },
  logisticInfoModal: { visible: false },
  logisticContractModal: { visible: false },
  deleteContractModal: { visible: false },
  confirmConnectModal: { visible: false },
  contractActiveStep: 0,
};

const logisticSettingsSlice = createSlice({
  name: 'logisticSettings',
  initialState,
  reducers: {
    setContractActiveStep(state, action: PayloadAction<number>) {
      state.contractActiveStep = action.payload;
    },
    setConnectModal(state, action: PayloadAction<ModalDataType<ConnectModalType>>) {
      state.connectModal = action.payload;
    },
    setLogisticInfoModal(state, action: PayloadAction<ModalDataType<LogisticInfoModel>>) {
      state.logisticInfoModal = action.payload;
    },
    setDeleteModal(state, action: PayloadAction<ModalDataType<DeleteModalType>>) {
      state.deleteContractModal = action.payload;
    },
    setContractModal(state, action: PayloadAction<ContractModalType>) {
      state.logisticContractModal = action.payload;
    },
    setConfirmConnectModal(state, action: PayloadAction<ModalDataType<ConfirmConnectModal>>) {
      state.confirmConnectModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncActions.getGroupedUserWarehouses.fulfilled, (state, action) => {
        state.groupedLocations = action.payload.getMandatsWarehouses;
      })
      .addCase(asyncActions.getLogisticsByWarehouse.fulfilled, (state, action) => {
        state.groupedLogistics = action.payload.logisticContracts;
      })
      .addCase(asyncActions.getDetailedLogisticInfo.fulfilled, (state, action) => {
        state.logisticInfoModal = { visible: true, data: action.payload };
      })

      .addCase(asyncActions.getLogisticContract.fulfilled, (state, action) => {
        state.logisticContractModal.contract = action.payload.contract;
      })
      .addCase(asyncActions.getDeutschePostContract.fulfilled, (state, action) => {
        state.logisticContractModal.contract = action.payload.contract;
      })

      .addCase(asyncActions.createLogisticContract.fulfilled, (state, action) => {
        const contract = action.payload.contract;

        state?.logisticInfoModal?.data?.contracts.push({
          id: Number(contract.id),
          name: contract.userName,
          isActive: contract.isActive,
        });
      })
      .addCase(asyncActions.createDeutschePostContract.fulfilled, (state, action) => {
        const contract = action.payload.contract;

        state?.logisticInfoModal?.data?.contracts.push({
          id: Number(contract.id),
          name: contract.portokasseUserName,
          isActive: contract.isActive,
        });
      })

      .addCase(asyncActions.updateLogisticContractState.fulfilled, (state, action) => {
        const contractIdx = state.logisticInfoModal.data?.contracts?.findIndex((item) => item.id === action.payload.id);
        if (contractIdx != undefined && state.logisticInfoModal.data) {
          state.logisticInfoModal.data.contracts[contractIdx].isActive = action.payload.isActive;
        }
      })
      .addCase(asyncActions.updateDeutschePostContractState.fulfilled, (state, action) => {
        const contractIdx = state.logisticInfoModal.data?.contracts?.findIndex((item) => item.id === action.payload.id);
        if (contractIdx != undefined && state.logisticInfoModal.data) {
          state.logisticInfoModal.data.contracts[contractIdx].isActive = action.payload.isActive;
        }
      })
      .addCase(asyncActions.updateLogisticContract.fulfilled, (state, action) => {
        const contract = action.payload.contract;
        const contractIdx = state.logisticInfoModal.data?.contracts.findIndex((item) => item.id == contract.id);

        if (contractIdx != undefined) {
          state.logisticInfoModal.data!.contracts[contractIdx].name = contract.name;
        }
      })
      .addCase(asyncActions.updateDeutschePostContract.fulfilled, (state, action) => {
        const contract = action.payload.contract;
        const contractIdx = state.logisticInfoModal.data?.contracts.findIndex((item) => item.id == contract.id);

        if (contractIdx != undefined) {
          state.logisticInfoModal.data!.contracts[contractIdx].name = contract.name;
        }
      })

      .addCase(asyncActions.deleteLogisticContract.fulfilled, (state, action) => {
        const contracts = state.logisticInfoModal?.data?.contracts?.filter((item) => item.id != action.payload);
        state.logisticInfoModal.data!.contracts = contracts!;
      })
      .addCase(asyncActions.deleteDeutschePostContract.fulfilled, (state, action) => {
        const contracts = state.logisticInfoModal?.data?.contracts?.filter((item) => item.id != action.payload);
        state.logisticInfoModal.data!.contracts = contracts!;
      });
  },
});

const { actions, reducer } = logisticSettingsSlice;

export const logisticSettingsActions = actions;
export const logisticSettingsReducer = reducer;
export const logisticSettingsAsyncActions = asyncActions;
