import React, { useMemo } from 'react';
import { TourType } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';
import { useDispatch, useSelector } from 'react-redux';
import { accountChartsDataSelectors } from '@/Redux/DynamicData/AccountChartsData/selectors';
import { accountChartsDataActions } from '@/Redux/DynamicData/AccountChartsData/reducer';

export function useAccountChartSettings() {
  const dispatch = useDispatch();

  const {
    tours: {
      OnlineArchiveSettings: { AccountChart, AccountChartModal },
    },
  } = useLocalization();

  const { accountChartType } = useSelector(accountChartsDataSelectors.accountChartsInfo);

  const pageTour = useMemo<TourType>(
    () => ({
      id: 'pageTour',
      title: AccountChart.Title,
      steps: [
        {
          content: <div>{AccountChart.Step1.Text}</div>,
          placement: 'auto',
          target: '#accountChart',
          title: AccountChart.Step1.Title,
          disableBeacon: true,
          link: AccountChart.Step1.DetailsLink,
        },
        {
          content: <div>{AccountChart.Step2.Text}</div>,
          placement: 'top',
          target: '#accountChartsTable',
          title: AccountChart.Step2.Title,
          disableBeacon: true,
          link: AccountChart.Step2.DetailsLink,
          floaterProps: {
            disableFlip: true,
          },
        },
        {
          content: <div>{AccountChart.Step3.Text}</div>,
          placement: 'auto',
          target: '#crudExtended',
          title: AccountChart.Step3.Title,
          disableBeacon: true,
          link: AccountChart.Step3.DetailsLink,
        },
        {
          content: <div>{AccountChart.Step4.Text}</div>,
          placement: 'auto',
          target: '.dx-toolbar-after',
          title: AccountChart.Step4.Title,
          disableBeacon: true,
          link: AccountChart.Step4.DetailsLink,
          spotlightPadding: 0,
          styles: {
            spotlight: {
              maxWidth: '500px',
            },
          },
        },
        {
          content: <div>{AccountChart.Step5.Text}</div>,
          placement: 'auto',
          target: '.dx-dropdownmenu-button',
          title: AccountChart.Step5.Title,
          disableBeacon: true,
          link: AccountChart.Step5.DetailsLink,
        },
      ],
    }),
    [AccountChart],
  );

  const accountChartSettingsTour = useMemo<TourType>(
    () => ({
      id: 'accountChartSettingsTour',
      title: 'Account chart',
      before: () => {
        if (accountChartType) {
          dispatch(accountChartsDataActions.showAccountChartModal({ accountChartType: accountChartType }));
        }
      },
      steps: [
        {
          content: <div>{AccountChartModal.Step1.Text}</div>,
          placement: 'auto',
          target: '#modalTitle',
          title: AccountChartModal.Step1.Title,
          disableBeacon: true,
          link: 'https://www.google.com/',
        },
        {
          content: <div>{AccountChartModal.Step2.Text}</div>,
          placement: 'auto',
          target: '#accountChartForm',
          title: AccountChartModal.Step2.Title,
          disableBeacon: true,
          link: 'https://www.google.com/',
        },
        {
          content: <div>{AccountChartModal.Step3.Text}</div>,
          placement: 'auto',
          target: '#isActiveToggle',
          title: AccountChartModal.Step3.Title,
          disableBeacon: true,
          link: 'https://www.google.com/',
        },
        {
          content: <div>{AccountChartModal.Step4.Text}</div>,
          placement: 'auto',
          target: '#modalNavButtons',
          title: AccountChartModal.Step4.Title,
          disableBeacon: true,
          link: 'https://www.google.com/',
        },
      ],
    }),
    [AccountChartModal, accountChartType],
  );

  return [pageTour, accountChartSettingsTour];
}
