import React, { useMemo } from 'react';
import { TourType } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';

export function useOnlineArchiveUpload() {
  const {
    tours: {
      OnlineArchive: { UploadTour },
    },
  } = useLocalization();

  const pageTour = useMemo<TourType>(
    () => ({
      id: 'pageTour',
      title: UploadTour.Title,
      steps: [
        {
          content: <div>{UploadTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#onlineArchivePage',
          title: UploadTour.Step1.Title,
          disableBeacon: true,
          link: UploadTour.Step1.DetailsLink,
        },
        {
          content: <div>{UploadTour.Step2.Text}</div>,
          placement: 'auto',
          target: '#onlineArchivePageSettings',
          title: UploadTour.Step2.Title,
          disableBeacon: true,
          link: UploadTour.Step2.DetailsLink,
        },
        {
          content: <div>{UploadTour.Step3.Text}</div>,
          placement: 'auto',
          target: '#upload',
          title: UploadTour.Step3.Title,
          disableBeacon: true,
          link: UploadTour.Step3.DetailsLink,
        },
        {
          content: <div>{UploadTour.Step4.Text}</div>,
          placement: 'auto',
          target: '#mandat',
          title: UploadTour.Step4.Title,
          disableBeacon: true,
          link: UploadTour.Step4.DetailsLink,
        },
        {
          content: <div>{UploadTour.Step5.Text}</div>,
          placement: 'auto',
          target: '#template',
          title: UploadTour.Step5.Title,
          disableBeacon: true,
          link: UploadTour.Step5.DetailsLink,
        },
        {
          content: <div>{UploadTour.Step6.Text}</div>,
          placement: 'auto',
          target: '#uploadSettings',
          title: UploadTour.Step6.Title,
          disableBeacon: true,
          link: UploadTour.Step6.DetailsLink,
        },
        {
          content: <div>{UploadTour.Step7.Text}</div>,
          placement: 'auto',
          target: '#documentDate',
          title: UploadTour.Step7.Title,
          disableBeacon: true,
          link: UploadTour.Step7.DetailsLink,
        },
        {
          content: <div>{UploadTour.Step8.Text}</div>,
          placement: 'auto',
          target: '#uploadFiles',
          title: UploadTour.Step8.Title,
          disableBeacon: true,
          link: UploadTour.Step8.DetailsLink,
        },
        {
          content: <div>{UploadTour.Step9.Text}</div>,
          placement: 'auto',
          target: '#uploadList',
          title: UploadTour.Step9.Title,
          disableBeacon: true,
          link: UploadTour.Step9.DetailsLink,
        },
        {
          content: <div>{UploadTour.Step10.Text}</div>,
          placement: 'auto',
          target: '#buttonsWrapper',
          title: UploadTour.Step10.Title,
          disableBeacon: true,
          link: UploadTour.Step10.DetailsLink,
        },
        {
          content: <div>{UploadTour.Step11.Text}</div>,
          placement: 'auto',
          target: '#scanFiles',
          title: UploadTour.Step11.Title,
          disableBeacon: true,
          link: UploadTour.Step11.DetailsLink,
        },
        {
          content: <div>{UploadTour.Step12.Text}</div>,
          placement: 'auto',
          target: '#adScreen',
          title: UploadTour.Step12.Title,
          disableBeacon: true,
          link: UploadTour.Step12.DetailsLink,
        },
      ],
    }),
    [UploadTour],
  );

  return [pageTour];
}
