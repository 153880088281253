import React, { useMemo } from 'react';
import { TourType } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';

export function useSales() {
  const {
    tours: {
      Sales: { Default },
    },
  } = useLocalization();

  const pageTour = useMemo<TourType>(
    () => ({
      id: 'pageTour',
      title: Default.Title,
      steps: [
        {
          content: <div>{Default.Step1.Text}</div>,
          placement: 'auto',
          target: '#salesPage',
          title: Default.Step1.Title,
          disableBeacon: true,
          link: Default.Step1.DetailsLink,
        },
        {
          content: <div>{Default.Step2.Text}</div>,
          placement: 'auto',
          target: '#salesPageSettings',
          title: Default.Step2.Title,
          disableBeacon: true,
          link: Default.Step2.DetailsLink,
        },
        {
          content: <div>{Default.Step3.Text}</div>,
          placement: 'auto',
          target: '.salesTable',
          title: Default.Step3.Title,
          disableBeacon: true,
          link: Default.Step3.DetailsLink,
        },
        {
          content: <div>{Default.Step4.Text}</div>,
          placement: 'auto',
          target: '#tableFilters',
          title: Default.Step4.Title,
          disableBeacon: true,
          link: Default.Step4.DetailsLink,
        },
        {
          content: <div>{Default.Step5.Text}</div>,
          placement: 'auto',
          target: '#tableControls',
          title: Default.Step5.Title,
          disableBeacon: true,
          link: Default.Step5.DetailsLink,
        },
        {
          content: <div>{Default.Step6.Text}</div>,
          placement: 'auto',
          target: '#orderStatusIcons',
          title: Default.Step6.Title,
          disableBeacon: true,
          link: Default.Step6.DetailsLink,
        },
        {
          content: <div>{Default.Step7.Text}</div>,
          placement: 'auto',
          target: '.dx-toolbar-after',
          title: Default.Step7.Title,
          disableBeacon: true,
          link: Default.Step7.DetailsLink,
          spotlightPadding: 0,
          styles: {
            spotlight: {
              maxWidth: '500px',
            },
          },
        },
        {
          content: <div>{Default.Step8.Text}</div>,
          placement: 'auto',
          target: '.dx-dropdownmenu-button',
          title: Default.Step8.Title,
          disableBeacon: true,
          link: Default.Step8.DetailsLink,
        },
        {
          content: <div>{Default.Step9.Text}</div>,
          placement: 'auto',
          target: '#headerMenu',
          title: Default.Step9.Title,
          disableBeacon: true,
          link: Default.Step9.DetailsLink,
        },
        {
          content: <div>{Default.Step10.Text}</div>,
          placement: 'auto',
          target: '#generalInfo',
          title: Default.Step10.Title,
          disableBeacon: true,
          link: Default.Step10.DetailsLink,
        },
        {
          content: <div>{Default.Step11.Text}</div>,
          placement: 'auto',
          target: '#orderItems',
          title: Default.Step11.Title,
          disableBeacon: true,
          link: Default.Step11.DetailsLink,
        },
      ],
    }),
    [Default],
  );

  return [pageTour];
}
